<template>
  <div id="main-wrapper">
    <div id="header" v-if="this.$router.currentRoute.value.name === 'home'">
      <button id="sign-in" class="button-large" @click="redirectToSignIn()">{{ $t('signIn.signIn') }}</button>
      <button id="sign-up" class="button-large" @click="redirectToSignUp()">{{ $t('signUp.signUp') }}</button>
    </div>
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view id="router-view" :class="{ blurred: showCookieConsent }" v-on:cookiesAccepted="cookiesAccepted" />

    <cookie-consent v-if="showCookieConsent" v-on:cookiesAccepted="cookiesAccepted" />

    <footer-view />
  </div>
</template>

<script>
import FooterView from './components/FooterView.vue';
import { useAssetsStore } from './stores/assets';
import CookieConsent from './components/CookieConsent.vue';

export default {
  components: { FooterView, CookieConsent },

  data() {
    return {
      assetsStore: useAssetsStore(),
      showCookieConsent: false,
      wantsToSignIn: false,
      wantsToSignUp: false,
    }
  },
  
  created() {
    document.title = "Monster Pets";

    const html = document.documentElement
    html.setAttribute('lang', 'en')

    this.loadAssets()

    if (window.localStorage.getItem('cookiesAccepted') == null || window.localStorage.getItem('cookiesAccepted') === 'false') {
      this.showCookieConsent = true;
    }
  },

  methods: {
    cookiesAccepted(val) {
      this.showCookieConsent = false

      if (val && this.wantsToSignIn) {
        this.wantsToSignIn = false
        this.$router.push('/sign-in')
        return
      }
      if (val && this.wantsToSignUp) {
        this.wantsToSignUp = false
        this.$router.push('/sign-up')
        return
      }
    },

    redirectToSignIn() {
      const cookiesAccepted = window.localStorage.getItem('cookiesAccepted') !== null && window.localStorage.getItem('cookiesAccepted') === 'true'
      if (cookiesAccepted) {
        this.wantsToSignIn = false
        this.$router.push('/sign-in')
        return
      }
      this.showCookieConsent = true
      this.wantsToSignIn = true
      this.wantsToSignUp = false
    },

    redirectToSignUp() {
      const cookiesAccepted = window.localStorage.getItem('cookiesAccepted') !== null && window.localStorage.getItem('cookiesAccepted') === 'true'
      if (cookiesAccepted) {
        this.wantsToSignUp = false
        this.$router.push('/sign-up')
        return
      }
      this.showCookieConsent = true
      this.wantsToSignUp = true
      this.wantsToSignIn = false
    },

    loadAssets() {
      let numberOfMonsters = 10
      let monsters = []
      let monsterAnimations = []
      for (let i = 1; i <= 9; i++) {
        monsters.push(require('@/assets/monsters/monster00' + i + '/monster00' + i + '.png'))

        let allAnimationsCovered = false
        let j = 1
        while(!allAnimationsCovered) {
          try {
            monsterAnimations.push(require('@/assets/monsters/monster00' + i + '/monster00' + i + '-' + j + '.png'))
            j++
          } catch (e) {
            allAnimationsCovered = true
          }
        }

        this.assetsStore.monsterAnimations = monsterAnimations
      }

      for (let i = 10; i <= numberOfMonsters; i++) {
        monsters.push(require('@/assets/monsters/monster0' + i + '/monster0' + i + '.png'))

        let allAnimationsCovered = false
        let j = 1
        while(!allAnimationsCovered) {
          try {
            monsterAnimations.push(require('@/assets/monsters/monster0' + i + '/monster0' + i + '-' + j + '.png'))
            j++
          } catch (e) {
            allAnimationsCovered = true
          }
        }

        this.assetsStore.monsterAnimations = monsterAnimations
      }
      this.assetsStore.monsters = monsters

      let numberOfPlatforms = 10
      let platforms = []
      for (let i = 1; i <= 9; i++) {
        platforms.push(require('@/assets/platforms/' + 'platform00' + i + '.png'))
      }
      for (let i = 10; i <= numberOfPlatforms; i++) {
        monsters.push(require('@/assets/platforms/' + 'platform0' + i + '.png'))
      }
      this.assetsStore.platforms = platforms

      let numberOfBackgrounds = 10
      let backgrounds = []
      for (let i = 1; i <= 9; i++) {
        backgrounds.push(require('@/assets/backgrounds/' + 'background00' + i + '.png'))
      }
      for (let i = 10; i <= numberOfBackgrounds; i++) {
        monsters.push(require('@/assets/backgrounds/' + 'background0' + i + '.png'))
      }
      this.assetsStore.backgrounds = backgrounds
    }
  }
}
</script>

<style lang="scss">
:root {
    --width: 100vw;
    --full-width: 100vw;
  
    --main-color: hsl(329, 100%, 50%);
    --font-color: hsl(210, 29%, 24%);

    --btn-color: hsl(0, 0%, 30%);
    --btn-color-hover: hsl(0, 0%, 50%);
    --btn-color-active: hsl(0, 0%, 100%);

    --background-color: hsl(0, 0%, 100%);
    --background-color-hover: hsl(0, 0%, 50%);
    --background-color-active: hsl(0, 0%, 30%);

    --btn-color-positive-background: hsl(0, 0%, 100%);
    --btn-color-positive-font: hsl(0, 0%, 30%);

    --btn-color-negative-background: hsl(0, 0%, 30%);
    --btn-color-negative-font: hsl(0, 0%, 100%);

    --yellow-light: rgb(255, 237, 0);
    --yellow-dark: rgb(255, 216, 0);

}

body {
  /* background-image: linear-gradient(-135deg, #ff0084, #33001b); */
  font-family: "Raleway", sans-serif;
  margin: 0;
}

html, body {
  height: 100%;
  width: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--font-color);
}

#main-wrapper {
  display: flex;
  flex-direction: column;
}

#header {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  padding: 1rem 0 0 0;
}

#sign-in {
  margin-bottom: 1rem;
}

#router-view {
  filter: blur(0);
  overflow-y: auto;
  height: 100vh;
}

#router-view.blurred {
  filter: blur(0);
}

.content {
  flex: 1 0 auto;
  height: 95vh;
}

#footer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  position: fixed;
  bottom: 0px;
  padding: 0.5rem 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 50px;
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%,rgba(255, 255, 255, 0) 100%);
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

button {
  border: none;
  background: transparent;
}

.menu-button {
  cursor: pointer;
}

.button-large {
  color: var(--main-color);
  background-color: transparent;
  border: 3px solid var(--main-color);
  border-radius: 25px;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;

  text-transform: uppercase;
  letter-spacing: 1.76px;
  font-weight: 700;
}

:is(.button-large, .button-large-positive, .button-large-negative, .image-button, .image-button-small, .image-button-smallest, .food-btn, .card):hover {
  transform: scale(1.04);
}

.image-button {
  border-radius: 25px;
  background-color: var(--yellow-light);
}

.image-button-small {
  border-radius: 12px;
  background-color: var(--yellow-light);
}

.image-button-smallest {
  border-radius: 5px;
  background-color: var(--yellow-light);
}

.image-button:active {
  background-color: var(--yellow-light);
  border-radius: 25px;
  box-shadow: 10px 10px 10px var(--btn-color-positive-font);
}

.image-button-small:active {
  border-radius: 12px;
  background-color: var(--yellow-light);
  box-shadow: 5px 5px 5px var(--btn-color-positive-font);
}

.image-button-smallest:active {
  border-radius: 5px;
  background-color: var(--yellow-light);
  box-shadow: 5px 5px 5px var(--btn-color-positive-font);
}

.food-btn:active {
  border-radius: 25px;
  box-shadow: 10px 10px 10px var(--btn-color-positive-font);
}

.button-large:active {
  background-color: var(--main-color);
  color: white;
}

.button-large-positive, .button-large-negative {
  font-size: 1rem;
  border-radius: 25px;
  height: 50px;
  margin: 0 1rem;
  text-transform: uppercase;
  letter-spacing: 1.76px;
  font-weight: 700;
  padding-left: 20px;
  padding-right: 20px;
  width: 150px;
}

.button-large-positive {
  color: var(--btn-color-positive-font);
  background-color: var(--btn-color-positive-background);

  border: 0.15rem solid var(--btn-color-positive-font);
}

.button-large-negative {
  color: var(--btn-color-negative-font);
  background-color: var(--btn-color-negative-background);

  border: 0.15rem solid var(--btn-color-negative-font);
}

.button-large-positive:active {
  color: var(--btn-color-positive-background);
  background-color: var(--btn-color-positive-font);
}

.button-large-negative:active {
  color: var(--btn-color-negative-background);
  background-color: var(--btn-color-negative-font);
}

a {
  color: var(--main-color);
}

h1 {
  text-transform: uppercase;
}

@media (min-width: 224px) {
  #header {
    justify-content: center;
    padding: 1rem 1rem 0 1rem;
  }

  #sign-in {
    margin: 0 1rem 1rem 1rem;
  }

  #sign-up {
    margin: 0 1rem 1rem 1rem;
  }  
}

@media (min-width: 330px) {
  #header {
    justify-content: flex-end;
  }
}

input, textarea {
  border-radius: 25px;
  padding: 0 0.5rem;
  border: 1px solid black;
}

input:not(.button-large) {
  height: 25px;
}

textarea {
  padding-top: 10px;
  padding-bottom: 10px;
}

select {
  background-color: white;
  color: black;
  outline: none;
  border-radius: 25px;
  padding: 0 0.5rem;
  border: 1px solid black;
  height: 25px;
}
</style>
