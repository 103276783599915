<template>
  <div id="wrapper">

  <h1 id="header" style="padding-top: 1rem;">{{ $t('newsletter.title') }}</h1>

    <div id="revue-embed">
      <form action="https://www.getrevue.co/profile/mopets/add_subscriber" method="post" id="revue-form" name="revue-form"  target="_blank">
      <div id="form-elements">
        <div id="wrapper_email" class="revue-form-group">
          <label for="member_email" id="label_email">{{ $t('newsletter.email') }}</label>
          <input class="revue-form-field" type="email" name="member[email]" id="member_email">
        </div>
        <div id="wrapper_first_name" class="revue-form-group">
          <label for="member_first_name" id="label_first_name">{{ $t('newsletter.firstName') }}</label>
          <input class="revue-form-field" type="text" name="member[first_name]" id="member_first_name">
        </div>
        <div id="wrapper_last_name" class="revue-form-group">
          <label for="member_last_name" id="label_last_name">{{ $t('newsletter.lastName') }}</label>
          <input class="revue-form-field" type="text" name="member[last_name]" id="member_last_name">
        </div>
      </div>
      <div class="revue-form-actions">
        <input type="submit" :value="$t('newsletter.subscribe')" name="member[subscribe]" id="member_submit" class="button-large">
      </div>
      <div class="revue-form-footer">{{ $t('newsletter.agree.part1') }} <a target="_blank" href="https://www.getrevue.co/terms">{{ $t('newsletter.agree.part2') }}</a> {{ $t('newsletter.agree.part3') }} <a target="_blank" href="https://www.getrevue.co/privacy">{{ $t('newsletter.agree.part4') }}</a>{{ $t('newsletter.agree.part5') }}</div>
      </form>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
#wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

#form-elements {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

#label_email, #label_first_name, #label_last_name {
  text-align: left;
  padding: 1rem 0 0.25rem 0;
}

#wrapper_email, #wrapper_first_name, #wrapper_last_name{
  display: flex;
  flex-direction: column;
}

.revue-form-field {
  width: 50vw;
}

.revue-form-actions {
  padding-bottom: 2rem;
}
</style>