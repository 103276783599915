<template>
  <div class="content">
    <div id="menu-container">
      <button id="back-btn" class="menu-btn image-button-small" @click="redirectToOverview()"></button>
    </div>

    <h1 style="text-transform: uppercase">{{ $t('messages.title') }}</h1>

    <div id="messages-container">
      <div class="message-wrapper" :class="{ active: selected === message.meta.id, unread: !userStore.messages.includes(message.meta.id) }" v-for="(message, idx) in messages" :key="idx" @click="markAsRead(message)">
          <h3 style="align-self: flex-start; padding-left: .5rem; padding-right: .5rem;">
            {{ message.languages[this.language].title }}
          </h3>

          <div class="message-content">
            <p v-for="(content, idx) in message.languages[this.language].content" :key="idx">
              {{ content }}
            </p>
          </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user'
import { retrieveMessages, persistMessagesState } from '@/firebase/db'
import { ref } from 'vue'

export default {

  data() {
    return {
      selected: undefined
    }
  },

  setup() {

    const messages = ref({});

    let loadMessages = async () => {
      messages.value = await retrieveMessages()
    }

    loadMessages()

    let language = (navigator.language || navigator.userLanguage || "en").substring(0, 2)

    return {
      userStore: useUserStore(),
      messages,
      language
    }
  },

  methods: {
    redirectToOverview() {
      this.$router.push("/monsters");
    },

    markAsRead(message) {
      if (!this.userStore.messages.includes(message.meta.id)) {
        this.userStore.messages.push(message.meta.id)
        persistMessagesState(this.userStore)
      }

      if (this.selected === message.meta.id) {
        this.selected = null
        return
      }

      this.selected = message.meta.id
    },

  }

}
</script>

<style lang="scss">
#menu-container {
  display: flex;
  justify-content: flex-start;
  padding-top: 1rem;
  padding-left: 1rem;
}

#back-btn {
  align-self: flex-start;

  background-image: url('@/assets/misc/arrow-left.png');
  background-size: cover;
  width: 50px;
  height: 50px;
}

#messages-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message-wrapper {
  border: 2px solid hsl(53, 100%, 47%);
  border-radius: 25px;
  width: 80vw;
  margin-bottom: 1rem;
  

  display: flex;
  flex-direction: column;

  max-height: 10vh;

  background: hsl(53, 100%, 57%);
  background: radial-gradient(circle, hsl(53, 100%, 57%) 0%, rgba(255,255,255,1) 0%, hsl(53, 100%, 57%) 100%);

  &:hover {
    transform: scale(1.04);
  }

  &.active {
    max-height: 80vw;
    padding-bottom: 1rem;

    .message-content {
      overflow: auto;
      text-overflow: unset;
      display: unset;
      text-align: left;
    }

  }

  &.unread {
    border: 2px solid hsl(45, 100%, 47%);

    background: hsl(45, 100%, 47%);
    background: radial-gradient(circle, hsl(45, 100%, 47%) 0%, rgba(255,255,255,1) 0%, hsl(45, 100%, 47%) 100%);
  }

}

.message-content {
  align-self: flex-start;
  padding-left: .5rem;
  padding-right: .5rem;
  display: none;
}
</style>