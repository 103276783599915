<template>
  <div id="footer">
    <router-link to="/impressum" class="footer-item">{{ $t('footer.siteNotice') }}</router-link>
    <router-link to="/datenschutz" class="footer-item">{{ $t('footer.dataProtection') }}</router-link>
    <router-link to="/contact" class="footer-item">{{ $t('footer.contact') }}</router-link>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.footer-item {
  position: relative;
  bottom: -25px;
  padding: 0 1rem;
}
</style>