export default {
  "landingPage": {
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wolltest du schon immer einen Freund haben, den du überall hin mitnehmen kannst?"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jemanden, der immer für dich da ist?"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate mal wer dein Freund sein möchte!"])},
    "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monster Pets"])},
    "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine digitalen Haustiere"])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füttere dein Monster Pet 🥪"])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiele mit deinem Monster Pet ⚽️"])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knuddel dein Monster Pet 🤗"])},
    "signUp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melde dich für unseren Newsletter an"])},
    "signUp2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", um über Monster Pet News informiert zu werden!"])}
  },
  "newsletter": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melde dich für unseren Newsletter mit Updates zu Monster Pets an!"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email-Adresse"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname (Optional)"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname (Optional)"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "agree": {
      "part1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der Anmeldung stimmst du Revues"])},
      "part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
      "part3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
      "part4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
      "part5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" zu."])}
    }
  },
  "game": {
    "modes": {
      "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leicht"])},
      "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittel"])},
      "hard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schwer"])}
    },
    "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du"])},
    "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unentschieden!"])},
    "playerWon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast gewonnen!"])},
    "monsterWon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " hat gewonnen!"])},
    "confirmQuit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du sicher, dass du das Spiel beenden möchtest?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "playAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch einmal spielen"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
  },
  "shop": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen"])},
    "confirmationPart1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchtest du ", _interpolate(_named("amount")), " ", _interpolate(_named("pluralizeBerry")), " für ", _interpolate(_named("price")), "x"])},
    "confirmationPart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kaufen?"])},
    "notEnoughMoney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast nicht genug Geld."])},
    "lessThanOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst nicht weniger als eine Beere kaufen."])}
  },
  "messages": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten"])}
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "friends": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freunde"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Freunde:"])}
    },
    "openRequests": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfragen"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine offenen Freundesanfragen:"])}
    },
    "yourId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Freunde-ID:"])},
    "idOfFriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID deines Freundes:"])},
    "nameOfFriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name deines Freundes:"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freundesanfrage senden"])},
    "deleteProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil löschen"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email-Adresse"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Nachricht"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
    "dropdown": {
      "values": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-- Bitte wähle eine Kategorie --"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feature Anfrage"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])}
      ],
      "messages": {
        "initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle eine Kategorie."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danke für deine Nachricht!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups! Es gab ein Problem beim Senden deiner Nachricht."])}
      }
    }
  },
  "monsterView": {
    "happiness": {
      "high": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " ist sehr glücklich"])},
      "medium": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " ist ok"])},
      "low": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " ist sehr unglücklich"])}
    },
    "replete": {
      "high": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " ist satt"])},
      "medium": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " ist ein bisschen hungrig"])},
      "low": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " ist sehr hungrig"])}
    },
    "love": {
      "high": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " liebt dich sooooooo sehr"])},
      "medium": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " mag dich"])},
      "low": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " mag dich nicht so sehr"])}
    },
    "feed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Füttere ", _interpolate(_named("monster"))])},
    "play": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Spiele mit ", _interpolate(_named("monster"))])}
  },
  "signUp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registriere dich bei Monster Pets"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email-Adresse"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort (mindestens 6 Zeichen)"])},
    "passwordConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort (wiederholen)"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])}
  },
  "signIn": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logge dich ein"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email-Adresse"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])}
  },
  "notFound": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups..."])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Seite konnte nicht gefunden werden. Vielleicht hat ein Monster Pet sie gegessen?!"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])}
  },
  "cookieConsent": {
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir nutzen Cookies, damit diese Seite funktioniert (Login-Funktionalität)."])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])}
  },
  "monsterSelection": {
    "monster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle dein Monster!"])},
    "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo lebt dein Monster?"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie heißt dein Monster?"])}
  },
  "footer": {
    "siteNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "dataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
  },
  "siteNotice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "information": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben gemäß § 5 TMG"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patricia Sauer"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tannenweg 3"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16540 Hohen Neuendorf"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
      "phone": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+49 (0) 171 170 94 26"])}
      },
      "email": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])}
      }
    },
    "disputeResolution": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbraucher­streit­beilegung/Universal­schlichtungs­stelle"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen."])}
    }
  },
  "moneyHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Monster-Geld"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "closeMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü schließen"])}
}