export function moveSlice(slice, slices) {
  const i = slices.indexOf(slice)

  const adjacentSlices = findAdjacentSlices(slice, slices)

  const maxPos = Math.sqrt(slices.length)
  for (let adjacentSlice of adjacentSlices) {
    if (adjacentSlice.x > maxPos || adjacentSlice.y > maxPos || adjacentSlice.x < 0 || adjacentSlice.y < 0) {
      continue
    }

    if (adjacentSlice.empty === true) {
      const newSlices = [ ...slices ]
      const oldSliceTmp = { ...slice }
      const oldPositionOfAdjacent = slices.indexOf(adjacentSlice)
      newSlices[i] = { ...adjacentSlice }
      newSlices[oldPositionOfAdjacent] = oldSliceTmp

      return newSlices
    }
  }

  return slices
}

export function findAdjacentSlices(slice, slices) {
  const n = Math.sqrt(slices.length);
  const i = slices.indexOf(slice)

  const left = i % n > 0 ? i - 1 : null;
  const right = i % n < (n-1) ? i + 1 : null;
  const top = i > (n-1) ? i - n : null;
  const bottom = i < (n * (n-1)) ? i + n : null;

  const adjacentSlices = [left, right, top, bottom]
    .filter((index) => index != null)
    .map((index) => slices[index]);

  return adjacentSlices
}

export function checkGameFinished(slices) {
  for (let i = 0; i < slices.length-1; i++) {
    if (slices[i].pos !== i) {
      return false;
    }
  }
  return true;
}