<template>
<div class="content">
  <div id="menu-container">
    <button id="back-btn" class="menu-btn image-button-small" @click="redirectToOverview()"></button>
  </div>

  <h1 style="text-transform: uppercase">{{ $t('profile.title') }}</h1>

  <div id="profile-container" style="display: flex; flex-flow: column; overflow-x: hidden;">
    <button id="delete-profile" class="button-large" v-if="currentView === 'profile'" @click="deleteProfile()" style="position: absolute; bottom: 0px; left: 50%; transform: translateX(-50%);">{{ $t('profile.deleteProfile') }}</button>
  </div>
</div>
</template>

<script>
import { useUserStore } from '@/stores/user'
import { getFriendUser, connectUsers, storeConnectionRequestInCurrentUser, storeConnectionRequestInOtherUser, deleteAccount } from '@/firebase/db'

export default {

  data() {
    return {
      userStore: useUserStore(),
      friendId: '',
      currentView: 'profile',
      profileMenuItems: [
        {
          name: 'connected',
          label: 'profile.friends.label'
        },
        {
          name: 'profile',
          label: 'profile.profile'
        },
        {
          name: 'requested',
          label: 'profile.openRequests.label'
        }
      ],
    }
  },

  methods: {
    
    redirectToOverview() {
      this.$router.push("/monsters");
    },

    sendConnectionRequest() {
      const uuidv4Regex = '^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$'
      if (!this.friendId.match(uuidv4Regex)) {
        // TODO: Display hint that the friendId is not a valid uuid
        return
      }

      let friend = getFriendUser(this.friendId)
      if (friend.outgoingConnectionRequests.contains(this.userStore.id)) {
        connectUsers() // add friendId to friends of current user, add current user to friends of other user
        return
      }

      storeConnectionRequestInCurrentUser(this.userStore) // set outgoingConnectionRequest from userStore.id, store in db
      storeConnectionRequestInOtherUser(friend) // get user with this id, set incomingConnectionRequest from userStore.id, store in db
    },

    deleteProfile() {
      deleteAccount(this.$router)
    }
  }

}
</script>

<style lang="scss">
#menu-container {
  display: flex;
  justify-content: flex-start;
  padding-top: 1rem;
  padding-left: 1rem;
}

#back-btn {
  align-self: flex-start;

  background-image: url('@/assets/misc/arrow-left.png');
  background-size: cover;
  width: 50px;
  height: 50px;
}

.gradient {
  position: relative;
  display: inline-block;
}

.gradient::after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display: inline-block;
}

#gradient-to-right:after {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 100%);
}

#gradient-to-left:after {
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 100%);
}

#profile-menu {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  .profile-menu-item {
    padding: 0.1rem 0.5rem;
    color: var(--main-color);
    background-color: transparent;
    padding: 0 0.5rem;
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 1.76px;
    font-weight: 700;
    }

}

#id-container {
  display: flex;
  justify-content: center;
}

#help-wrapper {
  align-self: center;
  width: fit-content;
}

#connected-friends, #requested-friends {
  margin-left: 3rem;
  margin-right: 3rem;
}

#delete-profile {
  margin-bottom: 100px;
  margin-left: 1rem;
  margin-right: 1rem;
}

@media (max-width: 375px) {
  #id-wrapper {
    max-width: 275px;
  }
}

@media (min-width: 670px) {
  #gradient-to-right:after, #gradient-to-left:after {
    background: transparent;
  }

  .gradient, #gradient-to-right, #gradient-to-left {
    pointer-events: none;
  }

  #profile-menu {

    .profile-menu-item:first-child {
      padding: 0.1rem 0.5rem;
      border-top: 1px solid var(--main-color);
      border-left: 1px solid var(--main-color);
      border-right: 0;
    }

    .profile-menu-item {
      padding: 0.1rem 0.5rem;
      color: var(--main-color);
      background-color: transparent;
      padding: 0 0.5rem;
      font-size: 1.5rem;
      text-transform: uppercase;
      letter-spacing: 1.76px;
      font-weight: 700;
      border-top: 1px solid var(--main-color);
      border-left: 1px solid var(--main-color);
      border-right: 1px solid var(--main-color);

      pointer-events: auto;
    }

    .profile-menu-item:last-child {
      padding: 0.1rem 0.5rem;
      border-top: 1px solid var(--main-color);
      border-right: 1px solid var(--main-color);
      border-left: 0;
    }

    .profile-menu-item:hover {
      padding: 0.1rem 0.5rem;
      color: white;
      background-color: var(--main-color);
      padding: 0 0.5rem;
      font-size: 1.5rem;
      text-transform: uppercase;
      letter-spacing: 1.76px;
      font-weight: 700;
      border-top: 1px solid var(--main-color);
      border-left: 1px solid var(--main-color);
      border-right: 1px solid var(--main-color);
    }
  }
}
</style>