<template>
  <div style="display: flex; flex-direction: column; margin-left:1 rem;">
    <h1 style="padding-top: 1rem;">{{ $t('notFound.title') }}</h1>

    <div id="home-wrapper">
      <router-link to="/">{{ $t('notFound.home') }}</router-link>
    </div>

    <div id="content-wrapper">

      <div style="margin: auto;">
        <img src="@/assets/misc/monster-thinking.png" class="monster-image" alt="A monster in thinking posture">
        <img src="@/assets/misc/monster-thinking.png" class="monster-image" alt="A monster in thinking posture">
        <img src="@/assets/misc/monster-thinking.png" class="monster-image" alt="A monster in thinking posture">
      </div>

      <div id="not-found-text">
        {{ $t('notFound.content') }}
      </div>

      <div style="margin: auto;">
        <img src="@/assets/misc/monster-thinking.png" class="monster-image" alt="A monster in thinking posture">
        <img src="@/assets/misc/monster-thinking.png" class="monster-image" alt="A monster in thinking posture">
        <img src="@/assets/misc/monster-thinking.png" class="monster-image" alt="A monster in thinking posture">
      </div>

    </div>
  </div>
</template>

<script>
export default {

  mounted() {
    let footer = document.getElementById('footer')
    footer.style.position = 'absolute'
    footer.style.bottom = '1rem'
    footer.style.right = '50%'
    footer.style.transform = 'translateX(50%)'
  },

  unmounted() {
    let footer = document.getElementById('footer')
    footer.style.position = 'inherit'
    footer.style.bottom = 'inherit'
    footer.style.right = 'inherit'
    footer.style.transform = 'inherit'
  }

}
</script>

<style>
#home-wrapper {
  margin-top: 1rem;
}

#content-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: auto;
  padding: 1rem 2rem;
  margin: 2rem 0;
}

.monster-image {
  max-width: 33%;
  height: auto;
}

#not-found-text {
  padding-top: 50px;
  padding-bottom: 50px;
  margin: auto;
}

@media (min-width: 300px) {
  #not-found-text {
    font-size: 1.5rem;
  }

  #home-wrapper {
    font-size: 1.5rem;
  }
}

@media (min-width: 376px) {
  #not-found-text {
    padding-top: 100px;
    padding-bottom: 100px;
    font-size: 1.5rem;
  }
}

@media (min-width: 500px) {
  .monster-image {
    max-width: 130px;
    height: auto;
  }
}
</style>