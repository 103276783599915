<template>
  <div v-if="foodId">
    <div id="closing-area" style="align-self: flex-end">
      <button 
        id="close" 
        class="menu-btn image-button-smallest"
        :title="$t('closeMenu')"
        @click="closeFoodDetailsMenu()"
      />
    </div>

    <div id="coins-inner">
      <img id="coin-img-inner" src="@/assets/misc/coin.png" :alt="$t('moneyHint')" width="50" height="50" />
      <label id="coin-label-inner" for="coin-img">{{ userStore.money }}</label>
    </div>

    <div id="confirmation-modal" v-if="showConfirmationModal">
      <div>
        <div style="position: relative">
          {{ $t('shop.confirmationPart1', { amount: amount, pluralizeBerry: pluralizeBerry, price: calculatePrice }) }}
          <img class="coin-img" src="@/assets/misc/coin.png" :alt="$t('moneyHint')" width="20" height="20" style="position: absolute; bottom: 0;" />
          <span style="margin-left: 20px;">{{ $t('shop.confirmationPart2') }}</span>
        </div>
      </div>

      <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; align-self: center; padding-top: 1rem;">
        <button id="confirm-buy" class="button-large-positive" @click="emitBuy">{{ $t('yes')}}</button>
        <button id="cancel-buy" class="button-large-negative" @click="closeConfirmationModal()">{{ $t('cancel')}}</button>
      </div>
    </div>

    <div id="less-than-one-modal" v-if="showLessThanOneModal">
      <div>
        <div style="position: relative">
          {{ $t('shop.lessThanOne') }}
        </div>
      </div>

      <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; align-self: center; padding-top: 1rem;">
        <button id="confirm-less-than-one" class="button-large-positive" @click="closeLessThanOneModal">{{ $t('ok')}}</button>
      </div>
    </div>

    <div id="not-enough-money-modal" v-if="showNotEnoughMoneyModal">
      <div>
        <div style="position: relative">
          {{ $t('shop.notEnoughMoney') }}
        </div>
      </div>

      <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; align-self: center; padding-top: 1rem;">
        <button id="confirm-not-enough-money" class="button-large-positive" @click="closeNotEnoughMoneyModal">{{ $t('ok')}}</button>
      </div>
    </div>

    <div id="food-icon-wrapper">
      <img :src="displayFood()" alt="" width="100" height="100">
      <p id="food-description">{{ displayDescription() }}</p>
    </div>

    <div id="state-improvement-wrapper">
        <div class="state-improvements" v-if="this.foods && this.foodId && this.foods[this.foodId].happiness > 0">
          <img src="@/assets/state/happiness/1.png" alt="" width="50" height="50">
          <img src="@/assets/misc/arrow.png" alt="" width="25" height="25">
        </div>
        <div class="state-improvements" v-if="this.foods && this.foodId && this.foods[this.foodId].replete > 0">
        <img src="@/assets/state/replete/1.png" alt="" width="50" height="50">
        <img src="@/assets/misc/arrow.png" alt="" width="25" height="25">
        </div>
        <div class="state-improvements" v-if="this.foods && this.foodId && this.foods[this.foodId].love > 0">
        <img src="@/assets/state/love/1.png" alt="" width="50" height="50">
        <img src="@/assets/misc/arrow.png" alt="" width="25" height="25">
        </div>
      </div>

    <div id="price-amount-wrapper">
      <div class="amount-wrapper">
        <input type="text" id="amount" size="3" value="1" @change="setAmount()" style="text-align: right;" @keyup.enter="openConfirmationModal()">
      </div>
      <div class="price-wrapper">
        <label class="price-label" for="coin-img">{{ calculatePrice }}x</label>
        <img class="coin-img" src="@/assets/misc/coin.png" :alt="$t('moneyHint')" width="50" height="50" />
      </div>
    </div>

    <div id="buy-btn-wrapper">
      <button id="buy-btn" class="button-large-positive" @click="openConfirmationModal()">{{ $t('shop.buy') }}</button>
    </div>
    
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user'
import { foods } from '../shop/foods'

export default {
  props: ['foodId', 'initialPrice'],

  data() {
    return {
      foods: foods,
      amount: 1,
      showConfirmationModal: false,
      showNotEnoughMoneyModal: false,
      showLessThanOneModal: false,
      userStore: useUserStore(),
      locale: (navigator.language || navigator.userLanguage || "en").substring(0, 2),
      berryTerms: {
        "en": ['berry', 'berries'],
        "de": ['Beere', 'Beeren']
      }
    }
  },

  methods: {
    closeFoodDetailsMenu() {
      document.getElementById('amount').value = 1
      this.amount = 1
      this.$emit('close-food-menu')
    },

    displayFood() {
      if (this.foodId !== null) {
        return require(`@/assets/food/${this.foodId}.png`)
      }
    },

    displayDescription() {
      if (this.foodId !== null) {
        return this.foods[this.foodId].description[this.locale] || this.foods[this.foodId].description['en']
      }
    },

    setAmount() {
      this.amount = document.getElementById('amount').value
    },

    emitBuy() {
      this.closeConfirmationModal()
      let amount = this.amount
      this.amount = 1
      this.$emit('buy', amount)
    },

    openConfirmationModal() {
      console.log(this.amount)
      if (this.amount < 0) {
        console.log('in if')
        this.showLessThanOneModal = true
        return
      }
      if (this.userStore.money < foods[this.foodId].price * this.amount) {
        this.showNotEnoughMoneyModal = true
        return
      }

      this.showConfirmationModal = true
    },

    closeConfirmationModal() {
      this.showConfirmationModal = false
    },

    closeNotEnoughMoneyModal() {
      this.showNotEnoughMoneyModal = false
    },

    closeLessThanOneModal() {
      this.amount = 1
      document.getElementById('amount').value = this.amount
      this.showLessThanOneModal = false
    }

  },

  computed: {
    calculatePrice() {
      if (this.amount > 0) {
        return this.amount * this.initialPrice
      }
      return this.initialPrice
    },

    pluralizeBerry() {
      if (parseInt(this.amount) === 1) {
        return (this.berryTerms[this.locale] || this.berryTerms['en'])[0]
      }

      return (this.berryTerms[this.locale] || this.berryTerms['en'])[1]
    }
  }

}
</script>

<style>
#close {
  background-image: url('@/assets/misc/close.png');
  background-size: cover;
  width: 25px;
  height: 25px;
  margin: 1rem;
}

#coins-inner {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;

  position: relative;
}

#coin-label-inner {
  position: relative;
  top: -0.5rem;
}

#food-icon-wrapper {
  display: flex;
  justify-content: flex-start;
  margin: 0 1rem 0 1rem;
}

#food-description {
  text-align: left;
}

#state-improvement-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.state-improvements {
  display: flex;
  justify-content: center;
  align-items: center;
}

#price-amount-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: baseline;
  margin: 0 1rem 0 1rem;
}

#amount {
  border-radius: 25px;
  padding: 0 0.5rem;
  border: 1px solid black;
}

#buy-btn-wrapper {
  margin-top: 1rem;
}

#buy-btn {
  margin-bottom: 1rem;
}

#confirm-buy {
  margin-bottom: 1rem;
}

#confirmation-modal, #not-enough-money-modal, #less-than-one-modal {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;

  z-index: 3;

  background: radial-gradient(var(--yellow-light), var(--yellow-dark));

  border: 1px solid black;
  border-radius: 25px;

  position: absolute;
  width: 60%;
  left: 20%;
  top: 40%;

  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>