import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { createPinia } from 'pinia'
import { useUserStore } from "./stores/user";
import { getAuth } from "firebase/auth";
import { getUserDataOnAuthStateChanged, getAssetsOnAuthStateChanged } from "./firebase/db";
import { useAssetsStore } from "./stores/assets";
import i18n from './i18n'
import VueConfetti from 'vue-confetti'

const pinia = createPinia()

createApp(App).use(i18n).use(router).use(pinia).use(VueConfetti).mount("#app");

const userStore = useUserStore()
const assetsStore = useAssetsStore()

getAuth().onAuthStateChanged((user) => {
  if (user) {
    getUserDataOnAuthStateChanged(user.uid, userStore)
    getAssetsOnAuthStateChanged(assetsStore)
    window.localStorage.setItem('monsterPetUid', user.uid)

    if (router.currentRoute.value.path === '/') {
      if (userStore.monsters?.length > 0) {
        router.push('/monsters')
      } else {
        router.push('/create')
      }
    }
  } else {
    userStore.uid = ''
    userStore.id = ''
    useUserStore.signedIn = false
    userStore.monsters = []

    window.localStorage.setItem('monsterPetUid', null)
  }
});