import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "@/components/LandingPage.vue";
import NewsletterSubscribe from "@/components/NewsletterSubscribe.vue";
import MonsterSelection from "@/components/MonsterSelection.vue";
import MonsterOverview from "@/components/MonsterOverview.vue";
import MonsterView from "@/components/MonsterView.vue";
import SignUp from "@/components/SignUp.vue";
import SignIn from "@/components/SignIn.vue";
import NotFound from "@/components/NotFound.vue";
import MessagesView from "@/components/MessagesView.vue";
import ProfileView from "@/components/ProfileView.vue";
import ShopView from "@/components/ShopView.vue";
import ImpressumView from "@/components/ImpressumView.vue";
import DatenschutzView from "@/components/DatenschutzView.vue";
import ContactView from "@/components/ContactView.vue";

export const routes = [
  {
    path: "/",
    name: "home",
    component: LandingPage,
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: NewsletterSubscribe,
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: SignUp,
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: SignIn,
  },
  {
    path: "/create",
    name: "create",
    component: MonsterSelection,
  },
  {
    path: "/monsters/:monsterId",
    name: "monster",
    component: MonsterView
  },
  {
    path: "/monsters", // TODO: Add UUID ?
    name: "monsters",
    component: MonsterOverview,
  },
  {
    path: "/messages",
    name: "messages",
    component: MessagesView,
  },
  {
    path: "/profile", // TODO: Add UUID ?
    name: "profile",
    component: ProfileView,
  },
  {
    path: "/shop",
    name: "shop",
    component: ShopView,
  },
  {
    path: "/impressum",
    name: "impressum",
    component: ImpressumView,
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    component: DatenschutzView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    component: NotFound 
  },
  {
    path: '/404',
    name: '404',
    component: NotFound
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
