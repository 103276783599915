<template>
  <div>
    <h1>{{ $t('signIn.title') }}</h1>

    <div>
      
      <div id="form-elements">
        <div id="wrapper_email" class="revue-form-group">
          <label for="member_email" id="label_email">{{ $t('signIn.email')}}</label>
          <input class="revue-form-field" type="email" id="member_email" v-model="email" size="30" @keyup.enter="signIn()">
        </div>
        <div id="wrapper_password" class="revue-form-group">
          <label for="member_password" id="label_password">{{ $t('signIn.password')}}</label>
          <input class="revue-form-field" type="password" id="member_password" v-model="password" size="30" @keyup.enter="signIn()">
        </div>
      </div>
      <div>
        <button class="button-large" @click="signIn()">{{ $t('signIn.signIn')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useUserStore } from "@/stores/user";
import { getUserData, getUserDataOnAuthStateChanged } from '@/firebase/db';

export default {

  props: [ 'redirect' ],

  data() {
    return {
      userStore: useUserStore(),
    }
  },

  setup() {   
    return {
      email: "",
      password: ""
    }
  },

  methods: {
    signIn() {
      if (this.email === "" || this.password === "") {
        console.log("either email, password, or confirmed password is null");
        return;
      }
     
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          this.userStore.signedIn = true
          this.userStore.uid = user.uid

          getUserData(this.userStore).then(async () => {
            if (this.redirect) {
              this.$router.push(`/${this.redirect}`)
            }

            await getUserDataOnAuthStateChanged(window.localStorage.getItem("monsterPetUid"), this.userStore);
            if (this.userStore.monsters?.length > 0) {
              this.$router.push('/monsters')
            } else {
              this.$router.push('/create')
            }
          })
        })
        .catch((error) => {
          console.log('error', error)
          const errorCode = error.code;
          const errorMessage = error.message;

          //openErrorModal()

          console.log(errorCode)
          console.log(errorMessage)
          // ..
        });

    },

  },

}
</script>

<style scoped>
#wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
}

#form-elements {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

#label_email, #label_password, #label_password_confirmed {
  text-align: left;
  padding: 1rem 0 0.25rem 0;
}

#wrapper_email, #wrapper_password, #wrapper_password_confirmed{
  display: flex;
  flex-direction: column;
}
</style>