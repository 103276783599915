<template>
<div class="content">

  <div id="menu-container">
    <button id="back-btn" class="menu-btn image-button-small" @click="redirectToOverview()"></button>

    <div id="coins">
      <img id="coin-img" src="@/assets/misc/coin.png" alt="Your monster money" width="50" height="50" />
      <label id="coin-label" for="coin-img">{{ userStore.money }}</label>
    </div>
  </div>

  <h1 style="text-transform: uppercase">{{ $t('shop.title') }}</h1>

  <FoodView 
    id="food-view" 
    :foodId="currentItemId" 
    :initialPrice="initialPrice" 
    :class="{ visible: isFoodViewVisible }" 
    @close-food-menu="closeFoodDetailsMenu()"
    @buy="buy"
    />

<!--  <button class="button-large" @click="showFoods()">Food</button> -->

  <div id="food-wrapper" v-if="displayFoods">
    <div class="food-price-wrapper" v-for="foodId in foodIds" :key="foodId">
      <button class="food-btn" @click="openFoodView(foodId)" :style=" { backgroundImage: 'url(' + displayFood(foodId) + ')' }">
      </button>      
    </div>
  </div>
</div>
</template>

<script>
import { useUserStore } from '@/stores/user';
import { persistItemsState, persistMoneyState } from '@/firebase/db';
import FoodView from './FoodView.vue';
import { foods } from '@/shop/foods';

export default {
  components: {
    FoodView
  },
  data() {
    return {
      userStore: useUserStore(),
      foodIds: [1, 2, 3, 4, 5],
      displayFoods: true,
      currentItemId: null,
      isFoodViewVisible: false,
    }
  },

  methods: {

    redirectToOverview() {
      this.$router.push("/monsters");
    },

    displayFood(foodId) {
      return require(`@/assets/food/${foodId}.png`)
    },

    showFoods() {
      this.displayFoods = true
    },

    openFoodView(foodId) {
      this.currentItemId = foodId
      this.initialPrice = foods[foodId].price
      this.isFoodViewVisible = true
    },

    closeFoodDetailsMenu() {
      this.currentItemId = null
      this.isFoodViewVisible = false
    },

    buy(amount) {
      amount = parseInt(amount)
      if (this.showFoods) {
        if (this.userStore.items === undefined) {
          this.userStore.items = { 
            food: [] 
          }
        }
        let food = this.userStore.items.food.find(f => f.id === this.currentItemId)
        if (food === undefined) {
          food = {
            id: this.currentItemId,
            quantity: amount
          }
          this.userStore.items.food.push(food)
        } else {
          food.quantity += amount
        }
        this.userStore.money -= foods[this.currentItemId].price * amount;
        this.closeFoodDetailsMenu()
        persistItemsState(this.userStore)
        persistMoneyState(this.userStore)

        this.closeFoodDetailsMenu()
      }
    },

  }
}
</script>

<style>
#menu-container {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-right: 1rem;
}

#food-view {
  display: none;
}

#food-view.visible {
  display: flex;
  flex-direction: column;
  
  width: 80vw;
  height: 80vh;
  /* height: 400px; */
  background: radial-gradient(var(--yellow-light), var(--yellow-dark));
  border: 1px solid black;

  border-radius: 25px;

  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 2;
}

#coins {
  display: flex;
  flex-direction: column;

  position: relative;
}

#coin-label {
  position: relative;
  top: -0.5rem;
}

.food-price-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: space-around;

  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
}

.price-wrapper, .amount-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.amount-wrapper {
  margin-top: 0.5rem;
}

#food-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, max-content));
  grid-row-gap: .5em;
  grid-column-gap: 1rem;
  justify-content: center;
  padding: 0 1rem;
}

.food-btn {
  background-size: cover;
  width: 100px;
  height: 100px;

  border: 1px solid black;
  border-radius: 25px;
}

#confirmation-modal {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;

  z-index: 2;

  background: radial-gradient(var(--yellow-light), var(--yellow-dark));

  border: 1px solid black;
  border-radius: 25px;

  position: absolute;
  width: 60%;
  left: 20%;
  top: 40%;

  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (min-width: 530px) {
  #food-description {
    align-self: center;
  }

  #state-improvement-wrapper, #price-amount-wrapper {
    width: 400px;
    align-self: center;
  }

  #price-amount-wrapper {
    padding-bottom: 57px;
  }

  #food-view.visible {
    height: fit-content;
  }
}

@media (min-width: 1000px) {
  #food-view.visible {
    max-width: 800px;
  }
}

@media (max-width: 300px) {
  #state-improvement-wrapper, #price-amount-wrapper {
    flex-direction: column;
    align-items: center;
  }

  #price-amount-wrapper {
    margin-top: 1rem;
  }
}

@media (max-width: 225px) {
  #food-icon-wrapper {
    flex-direction: column;
    align-items: center;
  }
}
</style>