<template>
  <div class="content">

    <div id="menu-container">
      <button id="back-btn" class="menu-btn image-button-small" @click="redirectToOverview()"></button>
    </div>

    <h1 style="text-transform: uppercase">{{ $t('contact.title') }}</h1>

    <form id="contact-form" action="https://formspree.io/f/xnqrlerj" method="post">
      <div id="contact-form-contents">
        <div id="name-container" class="container">
          <label id="name-label" for="name-input">{{ $t('contact.name') }}</label>
          <input type="text" name="name-input" id="name-input">
        </div>
        <div id="email-container" class="container">
          <label id="email-label" for="email-input">{{ $t('contact.email') }}</label>
          <input type="text" name="email-input" id="email-input">
        </div>
        <div id="category-container" class="container">
          <label id="category-label" for="category-input">{{ $t('contact.category') }}</label>
          <select name="categories" id="categories" @change="setColorToBlack()">
            <option value="intitial">{{ $t('contact.dropdown.values[0]') }}</option>
            <option value="feature-request">{{ $t('contact.dropdown.values[1]') }}</option>
            <option value="bug">{{ $t('contact.dropdown.values[2]') }}</option>
            <option value="other">{{ $t('contact.dropdown.values[3]') }}</option>
          </select>
        </div>
        <div id="message-container" class="container">
          <label id="message-label" for="message-input">{{ $t('contact.message') }}</label>
          <textarea rows="20" name="message-input" id="message-input"></textarea>
        </div>

        <input type="text" name="noonewillusethis" id="noonewillusethis">

        <div id="button-container">
          <input type="submit" id="send-button" class="button-large" :value="$t('contact.send')">
        </div>
      </div>
    </form>

    <p id="form-status">
    </p>

  </div>
</template>

<script>
import { useUserStore } from '@/stores/user'

export default {

  data() {
    return {
      userStore: useUserStore(),
      form: undefined
    }
  },

  mounted() {
    this.form = document.getElementById("contact-form")
    this.form.addEventListener("submit", this.send)

    var status = document.getElementById("form-status");
    if (status) {
      status.innerHTML = "";
      status.style.backgroundColor = 'white'
      status.style.color = 'white'
    }
  },

  methods: {
    redirectToOverview() {
      if (this.userStore.signedIn) {
        this.$router.push("/monsters");
      } else {
        this.$router.push("/");
      }
    },

    async send(event) {
      event.preventDefault();
      const honeypot = document.getElementById("noonewillusethis");
      if (honeypot.value.length > 0) {
        return
      }

      var status = document.getElementById("form-status");
      const categories = document.getElementById('categories')
      if (categories.value === 'intitial') {
        status.innerHTML = this.$t('contact.dropdown.messages.initial');
        status.style.backgroundColor = 'var(--main-color)'
        status.style.color = 'white'


        categories.style.border = '1px solid var(--main-color)'
        categories.style.color = 'var(--main-color)'

        return
      }

      var data = new FormData(event.target);
      fetch(event.target.action, {
        method: this.form.method,
        body: data,
        headers: {
          'Accept': 'application/json'
        }
      }).then(response => {
        if (response.ok) {
          status.innerHTML = this.$t('contact.dropdown.messages.success');
          status.style.backgroundColor = 'var(--yellow-dark)'
          status.style.color = 'black'
          // this.form.reset()
        } else {
          status.style.backgroundColor = 'var(--main-color)'
          status.style.color = 'white'
          status.innerHTML = this.$t('contact.dropdown.messages.error')

          /*
          response.json().then(data => {
            if (Object.hasOwn(data, 'errors')) {
              status.innerHTML = data["errors"].map(error => error["message"]).join(", ")
            } else {
              status.innerHTML = "Oops! There was a problem submitting your form"
            }
          }) 
          */
        }
      }).catch(() => {
        status.style.backgroundColor = 'var(--main-color)'
        status.style.color = 'white'
        status.innerHTML = this.$t('contact.dropdown.messages.error')
      });
    },

    setColorToBlack() {
      const categories = document.getElementById('categories')
      if (categories) {
        categories.style.border = '1px solid black'
        categories.style.color = 'black'

        var status = document.getElementById("form-status");
        status.innerHTML = "";
        status.style.backgroundColor = 'white'
        status.style.color = 'white'
      }
    }
  },

}
</script>

<style>
form {
  margin-bottom: 25px;
}

#form-status {
  width: 60vw;
  margin: auto;
  padding: 25px 10px;
  border-radius: 25px;
  margin-bottom: 50px;
}

#contact-form-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#name-container,
#email-container,
#message-container,
#category-container {
  display: flex;
  flex-direction: column;
  width: 60vw;
}

#name-label,
#email-label,
#message-label,
#category-label {
  text-align: left;
  padding: 1rem 0 0.25rem 0;
}

#message-container {
  margin-bottom: 1rem;
}

#noonewillusethis {
  display: none;
}
</style>