<template>
  <div>
    <div id="closing-area" style="align-self: flex-end">
      <button 
        id="close" 
        class="menu-btn image-button-smallest" 
        :title="$t('closeMenu')"
        @click="closeGame()"
      />
    </div>

    <div id="quit-confirmation" :class="{ visible: confirmQuit }">
      {{ $t('game.confirmQuit')}}

      <div id="quit-confirmation-button-container">
        <button id="quit-confirm-button" class="button-large-negative" @click="closeGame()">{{ $t('game.yes') }}</button>
        <button id="quit-deny-button" class="button-large-positive" @click="this.confirmQuit = false">{{ $t('game.no') }}</button>
      </div>
    </div>

    <div id="game-over" :class="{ visible: isGameOver }">
      <div id="winner-container">
        <div id="winner">
          {{ winner }}
        </div>
      </div>

      <div id="game-over-button-container">
        <div>
        <button
          id="back-button-quit"
          class="button-large-positive"
          @click="closeGame()">
          {{ $t('game.back') }}
        </button>
        </div>
        <div>
          <button id="play-again-button" class="button-large-positive" @click="newGame()">{{ $t('game.playAgain') }}</button>
        </div>
      </div>

    </div>

    <div id="score-container">
      <div id="user-score" :class="{ bold: isUserTurn }">{{ $t("game.player")}}: {{ userScore }}</div>
      <div id="ai-score" :class="{ bold: !isUserTurn }">{{ monsterName }}: {{ aiScore }}</div>
    </div>

    <div id="memory-container">
      <div class="card-container" v-for="(cardId, idx) in cards" :key="idx" ref="cards">
        <MemoryField 
          :id="'card' + idx"
          class="card-field"
          :class="{ clickable: clickableFields }"
          :ref="(el) => { cardFields[idx] = el }" 
          :cardId=cardId 
          :idx=idx
          v-on:flipped="flipped"/>
      </div>
    </div>

    <div id="mode-selection" :class="{ visible: selectMode }">
      <button id="easy" class="button-large-positive" @click="switchMode('easy')">{{ $t('game.modes.easy') }}</button>
      <button id="medium" class="button-large-positive" @click="switchMode('medium')">{{ $t('game.modes.medium') }}</button>
      <button id="hard" class="button-large-positive" @click="switchMode('hard')">{{ $t('game.modes.hard') }}</button>
    </div>

    <div id="continue-button-wrapper">
      <button id="continue-button" class="button-large-positive" :class="{ visible: displayContinueButton }" @click="flipCardsBack()">{{ $t('game.continue') }}</button>
    </div>
  </div>
  
</template>

<script>
import { useUserStore } from '@/stores/user'
import { persistMonsterState } from '@/firebase/db'
import MemoryField from './MemoryField.vue'
import { EASY, MEDIUM, HARD } from '@/gameModes'

export default {
  components: { MemoryField },

  data() {
    return {
      userStore: useUserStore(),
      card1: null,
      card2: null,
      idCard1: null,
      idCard2: null,
      idxCard1: null,
      idxCard2: null,
      isUserTurn: true,
      userScore: 0,
      aiScore: 0,
      cardFields: [],
      displayContinueButton: false,
      cards: [1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10],
      clickableFields: false,
      availableFields: [...Array(20).keys()],
      isGameOver: false,
      confirmQuit: false,
      selectMode: true,
      cardRefs: [],
      mode: EASY,
      knownCards: [
        {value: 1, indexes: []},
        {value: 2, indexes: []},
        {value: 3, indexes: []},
        {value: 4, indexes: []},
        {value: 5, indexes: []},
        {value: 6, indexes: []},
        {value: 7, indexes: []},
        {value: 8, indexes: []},
        {value: 9, indexes: []},
        {value: 10, indexes: []},
      ]
    }
  },

  mounted() {
    this.newGame()
  },

  methods: {
    closeGame() {
      if (!this.isGameOver && !this.confirmQuit) {
        this.confirmQuit = true
        return
      }

      this.resetGameState()
      this.$emit('closeGame')
    },

    flipped(cardId, idx, card) {
      if (this.idCard1 === null) {
        this.card1 = card
        this.idCard1 = cardId
        this.idxCard1 = idx
        return
      }
      if (this.idCard2 === null) {
        this.card2 = card
        this.idCard2 = cardId
        this.idxCard2 = idx
        this.compareCards()
      }
      
    },

    compareCards() {
      if (this.idCard1 === this.idCard2) {
        this.availableFields = this.availableFields.filter(f => f !== this.idxCard1)
        this.availableFields = this.availableFields.filter(f => f !== this.idxCard2)

        this.cardRefs.push(this.card1)
        this.cardRefs.push(this.card2)

        this.card1.markAsPairFound()
        this.card2.markAsPairFound()

        if (this.isUserTurn) {
          if (this.availableFields.length === 0) {
            setTimeout(() => {
              this.gameOver()  
            }, 500);
          }
          this.userScore++
        } else {
          this.aiScore++;

          if (this.availableFields.length > 0) {
            this.aiFlipCards()
          } else {
            setTimeout(() => {
              this.gameOver()  
            }, 500);
          }

        }

      } else {
        // console.group('compareCards')
        // console.log('pos', this.idxCard1, 'val', this.idCard1)
        // console.log('pos', this.idxCard2, 'val', this.idCard2)

        let values = [this.idCard1, this.idCard2]
        let indexes = [this.idxCard1, this.idxCard2]
                
        let rememberCards = [Math.random(), Math.random()]
        for (let [idx, rememberValue] of rememberCards.entries()) {
          // console.log(rememberValue)
          if (
            (this.mode === EASY && rememberValue < 0.3) || 
            (this.mode === MEDIUM && rememberValue < 0.5) ||
            (this.mode === HARD && rememberValue < 0.75)
          ) {
            // console.log('remembering', values[idx])
            let alreadyKnown = this.knownCards.find(c => c.value === values[idx])
            // console.log('alreadyKnown', alreadyKnown)
            if (!alreadyKnown.indexes.includes(indexes[idx])) { // index of current cardId was not known beforehand
              alreadyKnown.indexes.push(indexes[idx])
            }
            
            // console.log('this.knownCards', this.knownCards)
          }
        }

        // console.groupEnd()
        
        this.displayContinueButton = true;
        this.clickableFields = false;
      }

      this.idCard1 = null
      this.idCard2 = null
      this.idxCard1 = null
      this.idxCard2 = null

    },

    flipCardsBack() {
      this.card1.flipBack()
      this.card2.flipBack()

      this.idCard1 = null
      this.idCard2 = null
      this.idxCard1 = null
      this.idxCard2 = null

      this.displayContinueButton = false;
      this.clickableFields = true;

      if (this.isUserTurn) {
        this.isUserTurn = false
        this.aiFlipCards()
        this.clickableFields = false
      } else {
        this.isUserTurn = true
      }
    },

    aiFlipCards() {
      let size = this.availableFields.length
      let position1 = Math.floor(Math.random() * size)
      let position2 = Math.floor(Math.random() * size)

      // console.group('aiFlipCards')
      // console.log('flipped position', this.availableFields[position1])

      // only 2 fields left
      if (size === 2) {
        position1 = 0
        position2 = 1
      } else {
        while (position2 === position1) {
          position2 = Math.floor(Math.random() * size)
        }
      }

      setTimeout(() => {
        document.getElementById('card' + this.availableFields[position1]).click()
        // console.log(this.idCard1)
        let alreadyKnown = this.knownCards.find(c => c.value === this.idCard1)
        // console.log('alreadyKnown', alreadyKnown)
        // console.log('alreadyKnown.indexes', alreadyKnown.indexes)

          if (alreadyKnown.indexes.length === 1 && !alreadyKnown.indexes.includes(this.idxCard1)) {
            position2 = alreadyKnown.indexes[0]
            // console.error('1 selecting index', position2, 'cardId', this.idCard1)

            setTimeout(() => {
              // console.log('position2', position2)
              // console.log('flipping', 'card' + position2)
              document.getElementById('card' + position2).click()
            }, 1000)
          } else if (alreadyKnown.indexes.length === 2) {
            position2 = alreadyKnown.indexes.filter(i => i !== this.idxCard1)[0]
            // console.error('2 selecting index', position2, 'cardId', this.idCard1)

            setTimeout(() => {
              // console.log('position2', position2)
              // console.log('flipping', 'card' + position2)
              document.getElementById('card' + position2).click()
            }, 1000)
          } else {
            setTimeout(() => {
              // console.log('flipping', 'card' + this.availableFields[position2])
              document.getElementById('card' + this.availableFields[position2]).click()
            }, 1000)  
          }

      }, 1000)
      // console.groupEnd()

    },

    switchMode(mode) {
      switch (mode) {
        case EASY:   this.mode = EASY;
                     break;
        case MEDIUM: this.mode = MEDIUM;
                     break;
        case HARD:   this.mode = HARD;
                     break;
        default: console.log('unknown mode', mode)
      }
      this.selectMode = false
      this.clickableFields = true
    },

    gameOver() {
      this.isGameOver = true
      document.getElementById('memory-container').style.filter = 'blur(4px)'
      document.getElementById('score-container').style.filter = 'blur(4px)'
      document.getElementById('closing-area').style.filter = 'blur(4px)'

      for (let stateToIncrease of ['love', 'happiness']) {
        this.currentMonster.state[stateToIncrease].level += 10
        if (this.currentMonster.state[stateToIncrease].level > 100) {
          this.currentMonster.state[stateToIncrease].level = 100
        }
        this.currentMonster.state[stateToIncrease].lastModified = Date.now();
      }

      persistMonsterState(this.userStore)
    },

    newGame() {
      function shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
      }

      shuffle(this.cards)

      this.resetGameState()
    },

    resetGameState() {
      for(const card of this.cardRefs) {
        card.flipBack()
      }

      this.isUserTurn = true
      this.clickableFields = false
      this.userScore = 0
      this.aiScore = 0

      this.isGameOver = false
      this.confirmQuit = false
      this.selectMode = true

      this.cardRefs = []
      this.availableFields = [...Array(20).keys()]
      this.knownCards = [
        {value: 1, indexes: []},
        {value: 2, indexes: []},
        {value: 3, indexes: []},
        {value: 4, indexes: []},
        {value: 5, indexes: []},
        {value: 6, indexes: []},
        {value: 7, indexes: []},
        {value: 8, indexes: []},
        {value: 9, indexes: []},
        {value: 10, indexes: []},
      ]

      document.getElementById('memory-container').style.filter = 'blur(0)'
      document.getElementById('score-container').style.filter = 'blur(0)'
      document.getElementById('closing-area').style.filter = 'blur(0)'
    },
  },
  
computed: {
  currentMonster() {
    const route = this.$router.currentRoute
    return this.userStore.monsters.find(m => String(m.birthday) === route.value.params.monsterId)
  },

  monsterName() {
    return this.currentMonster.name
  },

  winner() {
    if (this.userScore === this.aiScore) {
      return this.$root.$t('game.draw')
    }
    if (this.userScore > this.aiScore) {
      return this.$root.$t('game.playerWon')
    }

    return this.$root.$t('game.monsterWon', { monster: this.monsterName })
  }
}
    
}
</script>

<style lang="scss">
#memory-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, max-content));
  grid-row-gap: 0px;
  grid-column-gap: 1rem;
  justify-content: center;
  overflow-y: auto;
  padding: 0 1rem 0 1rem;
  width: 80%;
  align-self: center;
}

#score-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 0 1rem;
}

.card-container {
  margin: auto;
}

#continue-button.visible {
  display: initial;
  align-self: center;
  z-index: 3;
}

.card-field.clickable {
  pointer-events: all;
}

.card-field {
  pointer-events: none;
}

#user-score, #ai-score, #winner, #back-button, #play-again-button {
  padding: 0.5rem 1rem;
}

:is(#user-score, #ai-score).bold {
  font-weight: bolder;
  border: 2px solid var(--font-color);
  border-radius: 25px;
  background-color: white;
}

#game-over, #mode-selection, #continue-button {
  display: none;
}

#mode-selection.visible {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;

  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);

  background: radial-gradient(var(--yellow-light), var(--yellow-dark));
  border: 1px solid black;
  border-radius: 25px;

  width: 200px;

  .mode-button {
    margin: 1rem 0;
    width: 150px;

    color: var(--font-color);

    border: 2px solid var(--font-color);
    background-color: white;
  }
}

#easy, #medium, #hard {
  margin-bottom: 1rem;
}

#easy {
  margin-top: 1rem;
}

#game-over.visible {
  display: flex;
  flex-direction: column;
  z-index: 3;

  #winner-container {
    display: flex;
    justify-content: center;
    padding: 1rem 1rem;
  }

  #winner {
    font-weight: bolder;
    border: 2px solid var(--font-color);
    border-radius: 25px;
    background-color: white;
  }

  #game-over-button-container {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);

    display: flex;
    flex-direction: column-reverse;
    padding: 1rem 1rem;
  }

}

#play-again-button, #back-button-quit {
  width: 200px;
}

#play-again-button {
  margin-bottom: 1rem;
}

#quit-confirmation {
  display: none;
}

#quit-confirmation.visible {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;

  #quit-confirmation-button-container {
    display: flex;
    justify-content: space-around;
    padding: 1rem 1rem;
  }
}

@media (min-width: 375px) {
  #memory-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 75px 75px 75px 75px 75px;
    width: 250px;

    padding: 1rem 1rem 0 1rem;
  }
}

@media (min-width: 500px) {
  #continue-button {
    margin-top: 1rem;
  }
}
</style>