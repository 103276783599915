<template>
  <div class="content" v-if="activeMonster !== undefined">

    <div id="amount"></div>
    <button id="present-img" :class="{ visible: showPresentImg }" @click="openPresent()" />

    <MemoryGameView id="memory-game" :class="{ visible: displayMemoryGame }" v-on:closeGame="closeGame" />
    <PuzzleGameView id="puzzle-game"  :class="{ visible: displayPuzzleGame }" v-on:closeGame="closeGame" />

    <div id="food-menu" :class="{ visible: isFoodMenuVisible }">
      <div id="closing-area" style="align-self: flex-end">
        <button id="close" class="menu-btn image-button-smallest" :title="$t('closeMenu')" @click="closeFoodMenu()" />
      </div>

      <div id="foods" v-if="userStore.items">
        <div class="food" v-for="food in userStore.items.food" :key="food.id">
          <div class="food-item" v-if="food !== undefined && food.quantity > 0" :class="{ visible: food !== undefined && food.quantity > 0 }">
            <div>
              <button class="food-btn" @click="feedMonster(food)"
                :style="{ backgroundImage: 'url(' + displayFood(food.id) + '), radial-gradient(var(--yellow-light), var(--yellow-dark))' }"></button>
              <div class="quantity-pseudo" :data-count="food.quantity"></div>
            </div>

            <p class="food-description">{{ displayDescription(food.id) }}</p>

            <div class="state-improvement-wrapper">
              <div class="state-improvements" v-if="this.foods && this.foods[food.id].happiness > 0">
                <img src="@/assets/state/happiness/1.png" alt="" width="50" height="50">
                <img src="@/assets/misc/arrow.png" alt="" width="25" height="25">
              </div>
              <div class="state-improvements" v-if="this.foods && this.foods[food.id].replete > 0">
                <img src="@/assets/state/replete/1.png" alt="" width="50" height="50">
                <img src="@/assets/misc/arrow.png" alt="" width="25" height="25">
              </div>
              <div class="state-improvements" v-if="this.foods && this.foods[food.id].love > 0">
                <img src="@/assets/state/love/1.png" alt="" width="50" height="50">
                <img src="@/assets/misc/arrow.png" alt="" width="25" height="25">
              </div>
            </div>

            <hr>
          </div>
        </div>
      </div>
    </div>

    <div id="game-menu" :class="{ visible: isGameMenuVisible }">
      <div id="closing-area" style="align-self: flex-end">
        <button id="close" class="menu-btn image-button-smallest" :title="$t('closeMenu')" @click="closeGameMenu()" />
      </div>
      <div id="games">
        <div v-for="game in games" :key="game">
          <button :id="game" class="game-btn image-button-small" @click="play(game)"
            :style="{ backgroundImage: 'url(' + displayGame(game) + ')' }"></button>
        </div>
      </div>
    </div>

    <div id="monster-view-container" :style="{ backgroundImage: 'url(' + selectedBackground + ')' }">
      <div id="menu-container">
        <button id="back-btn" class="menu-btn image-button-small" @click="redirectToOverview()"></button>
        <span id="timer" v-if="countdownDifference > 0">{{ countdownTimer }}</span>
        <button id="present-btn" v-if="countdownDifference <= 0" @click="receivePresent()" />
      </div>

      <div id="name-wrapper">
        <h2 id="name">
          {{ activeMonster.name }}
        </h2>
      </div>

      <div id="states">
        <div v-for="(state, idx) in states" :key="idx">
          <img :id="defineId(state)" :src="displayState(state)" :alt="state" :title="displayTitle(state)" width="100"
            height="100" style="z-index: 2;">
        </div>
      </div>

      <div id="monster-area">
        <div>
          <img id="monster" :src="selectedMonster" :alt="activeMonster.name" draggable="false" />
        </div>
      </div>

      <div id="menu">
        <button id="feed" class="menu-btn image-button-small"
          :title="$t('monsterView.feed', { monster: this.activeMonster.name })" @click="openFoodMenu()" />
        <button id="play" class="menu-btn image-button-small"
          :title="$t('monsterView.play', { monster: this.activeMonster.name })" @click="openGameMenu()" />
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user'
import { useAssetsStore } from '@/stores/assets'
import { persistMonsterState, persistItemsState, persistMoneyState, getMonsterData, getItemData } from '@/firebase/db'
import { getUserDataOnAuthStateChanged, getAssetsOnAuthStateChanged } from "../firebase/db"
import { foods } from '../shop/foods'
import { HAPPINESS, LOVE, REPLETE, states } from '../states'
import { COLLECT, JEWELZ, MEMORY, PUZZLE } from '../games'
import MemoryGameView from './MemoryGameView.vue'
import PuzzleGameView from './PuzzleGameView.vue'

export default {
  components: { MemoryGameView, PuzzleGameView },

  data() {
    return {
      activeMonster: {
        monsterId: -1,
        platformId: -1,
        name: "",
        birthday: -1
      },
      userStore: useUserStore(),
      assetsStore: useAssetsStore(),
      states: [HAPPINESS, REPLETE, LOVE],
      isFoodMenuVisible: false,
      isGameMenuVisible: false,
      games: ['memory', 'puzzle'], // 'jewelz', 'collect'],
      heartCache: [],
      countdownTimer: null,
      countdownDifference: null,
      showPresentImg: false,
      foods: foods,
      displayMemoryGame: false,
      displayJewelzGame: false,
      displayCollectGame: false,
      displayPuzzleGame: false,
      locale: (navigator.language || navigator.userLanguage || "en").substring(0, 2),
      countdownInterval: null,
      countdownInterval2: null,
    };
  },

  async created() {
    if (this.userStore.monsters.length === 0) {
      await getUserDataOnAuthStateChanged(window.localStorage.getItem("monsterPetUid"), this.userStore);
      await getAssetsOnAuthStateChanged(this.assetsStore);
    }

    this.userStore.items = await getItemData(this.userStore)
    this.userStore.monsters = await getMonsterData(this.userStore)

    // sort food descending by improved states
    if (this.userStore.items && this.userStore.items.food) {
      this.userStore.items.food.sort((a, b) => {
        let food1 = this.foods[a.id]
        let food2 = this.foods[b.id]
        if ((food1.happiness + food1.replete + food1.love) - (food2.happiness + food2.replete + food2.love) === 0) {
          return a.id - b.id
        }
        return (food2.happiness + food2.replete + food2.love) - (food1.happiness + food1.replete + food1.love)
      })
      persistItemsState(this.userStore)
    }

    const route = this.$router.currentRoute;
    this.activeMonster = this.userStore.monsters.find(m => String(m.birthday) === route.value.params.monsterId);

    if (this.activeMonster === undefined) {
      this.$router.push('/404')
      return
    }

    const threeHours = 10800000;
    const now = Date.now();
    for (let state of this.states) {
      const lastModifiedDiff = now - this.activeMonster.state[state].lastModified;
      // console.log("old state", this.activeMonster.state[state].level);
      // console.log("old lastModified", this.activeMonster.state[state].lastModified);
      this.activeMonster.state[state].level = this.activeMonster.state[state].level - (10 * Math.floor(lastModifiedDiff / threeHours));
      this.activeMonster.state[state].lastModified = now;
      if (isNaN(this.activeMonster.state[state].level) || this.activeMonster.state[state].level < 0) {
        this.activeMonster.state[state].level = 0;
      }
      // console.log("new state", this.activeMonster.state[state].level);
      // console.log("new lastModified", this.activeMonster.state[state].lastModified);
    }
    persistMonsterState(this.userStore);

    if (isNaN(this.activeMonster.lastVisit)) {
      this.activeMonster.lastVisit = Date.now()
      persistMonsterState(this.userStore)
    }
    let lastVisit = this.activeMonster.lastVisit
    this.countdownDifference = 3600000 - (Date.now() - new Date(lastVisit))

    if (this.countdownDifference > 0) {
      // convert to nanos
      this.countdownDifference /= 1000

      let countdownInterval = setInterval(() => {
        this.countdownDifference--
        let seconds = Math.floor(this.countdownDifference / 1) % 60
        let minutes = Math.floor(this.countdownDifference / 60) % 60
        this.countdownTimer = `${minutes}:${seconds}`

        if (this.countdownDifference <= 0) {
          console.log('before clearing', this.countdownDifference)
          clearInterval(countdownInterval)
        }
      }, 1000)
    }

  },

  mounted() {
    let happySound = new Audio()
    happySound.src = require('@/assets/sounds/happy_sound_monsters.mp3')
    happySound.volume = 0.2

    const cuddling = () => {

      for (let i = 0; i < 5; i++) {

        let heart = this.heartCache.pop()
        if (heart === undefined) {
          heart = document.createElement("img");
          heart.src = require(`@/assets/state/love/1.png`)
          heart.width = 50
          heart.height = 50
          heart.className = 'heart'
          heart.style.position = 'absolute'
        }

        heart.style.top = '50%'

        let rightRand = Math.floor(Math.random() * 40)
        heart.style.right = 30 + rightRand + '%'

        heart.style['-webkit-transform'] = 'translate(50%, -50%)'
        heart.style['transform'] = 'translate(50%, -50%)'

        document.body.appendChild(heart)

        let xUpRand = Math.floor(Math.random() * 100)
        let yUpRand = Math.floor(Math.random() * 100)

        let xDirection = 1
        if (Math.random() > 0.5) {
          xDirection = -1
        }

        let xDestinationUp = (heart.x - 25 + xUpRand) * xDirection + 'px'
        let yDestinationUp = (-1 * (heart.y - 25 - yUpRand)) + 'px'

        // move heart
        heart.animate([
          { transform: `translate(50%, -50%)` },
          { transform: `translate(${xDestinationUp}, ${yDestinationUp})` },
        ],
          {
            delay: 100,
            duration: 3000,
            iterations: 1
          })

        heart.animate([
          { WebkitTransition: `translate(50%, -50%)` },
          { WebkitTransition: `translate(${xDestinationUp}, ${yDestinationUp})` },
        ],
          {
            delay: 100,
            duration: 3000,
            iterations: 1
          })

        // fade heart out
        heart.animate([
          { opacity: 1 },
          { opacity: 0 }
        ],
          {
            delay: 2100,
            duration: 1000,
            iterations: 1
          })

        // remove heart when both animations are finished
        Promise.all(heart.getAnimations().map(anim => anim.finished)).then(() => {
          heart.remove()
          this.heartCache.push(heart)

          let heartsOnScreen = document.getElementsByClassName('heart')
          if (!heartsOnScreen || heartsOnScreen.length === 0) {
            document.getElementById('love-img').classList.remove('animate')
            document.getElementById('happiness-img').classList.remove('animate')
          }
        })
      }

      document.getElementById('love-img').classList.add('animate')
      document.getElementById('happiness-img').classList.add('animate')

      happySound.play()

      if (Math.random() > 0.5) {
        this.activeMonster.state[LOVE].level += 1;

        if (this.activeMonster.state[LOVE].level > 100) {
          this.activeMonster.state[LOVE].level = 100
        }

        this.activeMonster.state[LOVE].lastModified = Date.now();
      }

      if (Math.random() > 0.5) {
        this.activeMonster.state[HAPPINESS].level += 1;

        if (this.activeMonster.state[HAPPINESS].level > 100) {
          this.activeMonster.state[HAPPINESS].level = 100
        }

        this.activeMonster.state[HAPPINESS].lastModified = Date.now();
      }
    }

    setTimeout(() => {
      let monster = document.getElementById('monster')
      monster.addEventListener('pointerdown', () => {
        cuddling()

        monster.addEventListener('pointerup', () => {
          persistMonsterState(this.userStore)
        }, { once: true })
      })
    }, 2000);

  },

  methods: {
    redirectToOverview() {
      if (this.countdownInterval !== null) {
        clearInterval(this.countdownInterval)
      }

      if (this.countdownInterval2 !== null) {
        clearInterval(this.countdownInterval2)
      }

      this.$router.push("/monsters");
    },

    displayState(state) {
      if (this.activeMonster.state[state].level > 70) {
        return require(`@/assets/state/${state}/1.png`)
      }
      if (this.activeMonster.state[state].level > 30) {
        return require(`@/assets/state/${state}/2.png`)
      }

      return require(`@/assets/state/${state}/3.png`)
    },

    defineId(state) {
      return `${state}-img`
    },

    displayTitle(state) {
      switch (state) {
        case HAPPINESS:
          return this.displayHappinessState()
        case LOVE:
          return this.displayLoveState()
        case REPLETE:
          return this.displayRepleteState()
      }
    },

    displayHappinessState() {
      if (this.activeMonster.state[HAPPINESS].level > 70) {
        return this.$root.$t('monsterView.happiness.high', { monster: this.activeMonster.name })
      }

      if (this.activeMonster.state[HAPPINESS].level > 30) {
        return this.$root.$t('monsterView.happiness.medium', { monster: this.activeMonster.name })
      }

      return this.$root.$t('monsterView.happiness.low', { monster: this.activeMonster.name })
    },

    displayLoveState() {
      if (this.activeMonster.state[LOVE].level > 70) {
        return this.$root.$t('monsterView.love.high', { monster: this.activeMonster.name })
      }

      if (this.activeMonster.state[LOVE].level > 30) {
        return this.$root.$t('monsterView.love.medium', { monster: this.activeMonster.name })
      }

      return this.$root.$t('monsterView.love.low', { monster: this.activeMonster.name })
    },

    displayRepleteState() {
      if (this.activeMonster.state[REPLETE].level > 70) {
        return this.$root.$t('monsterView.replete.high', { monster: this.activeMonster.name })
      }

      if (this.activeMonster.state[REPLETE].level > 30) {
        return this.$root.$t('monsterView.replete.medium', { monster: this.activeMonster.name })
      }

      return this.$root.$t('monsterView.replete.low', { monster: this.activeMonster.name })
    },

    displayDescription(foodId) {
      if (foodId !== null) {
        return foods[foodId].description[this.locale] || foods[foodId].description['en']
      }
    },

    async openFoodMenu() {
      this.userStore.items = await getItemData(this.userStore)
      this.isFoodMenuVisible = true
    },

    closeFoodMenu() {
      this.isFoodMenuVisible = false
    },

    displayFood(food) {
      return require(`@/assets/food/${food}.png`)
    },

    async feedMonster(food) {
      this.userStore.monsters = await getMonsterData(this.userStore)
      this.activeMonster = this.userStore.monsters.find(m => m.birthday === this.activeMonster.birthday)

      this.closeFoodMenu()
      food.quantity--;

      function animateBlink(obj) {
        obj.animate(
          [
            { opacity: 1 },
            { opacity: 0 },
            { opacity: 1 }
          ],
          {
            delay: 0,
            duration: 2000,
            iterations: 1
          }
        )
      }

      let foodImage = new Image()
      foodImage.src = require(`@/assets/food/${food.id}.png`)
      foodImage.height = 100
      foodImage.width = 100
      foodImage.style.position = 'absolute'
      foodImage.style.top = 'calc(50% - 50px)'
      foodImage.style.left = 'calc(50% - 50px)'
      foodImage.style.zIndex = 5

      foodImage.animate(
        [
          { top: 'calc(50% - 100px)' },
          { top: 'calc(50% - 50px)' },
        ],
        {
          delay: 0,
          duration: 500,
          iterations: 3
        }
      )

      let audio = new Audio()
      audio.src = require('@/assets/sounds/eat.wav');
      audio.play();
      let eatingSoundInterval = setInterval(() => {
        audio.play();
      }, 10)

      // stop eating sound
      setTimeout(() => {
        clearInterval(eatingSoundInterval)
      }, 1500)

      // remove food image
      setTimeout(() => {
        document.body.removeChild(foodImage)
      }, 1500)

      document.body.appendChild(foodImage)

      if (this.foods[food.id][LOVE] > 0) {
        animateBlink(document.getElementById('love-img'))
      }
      if (this.foods[food.id][HAPPINESS] > 0) {
        animateBlink(document.getElementById('happiness-img'))
      }
      if (this.foods[food.id][REPLETE] > 0) {
        animateBlink(document.getElementById('replete-img'))
      }
      for (let state of states) {
        this.activeMonster.state[state].level += this.foods[food.id][state]
        if (this.activeMonster.state[state].level > 100) {
          this.activeMonster.state[state].level = 100
        }
        this.activeMonster.state[state].lastModified = Date.now();
      }

      persistItemsState(this.userStore)
      persistMonsterState(this.userStore)
    },

    openGameMenu() {
      this.isGameMenuVisible = true
    },

    closeGameMenu() {
      this.isGameMenuVisible = false
    },

    displayGame(game) {
      return require(`@/assets/menu/${game}.png`)
    },

    play(game) {
      switch (game) {
        case MEMORY:
          this.displayMemoryGame = true;
          break;
        case JEWELZ:
          this.displayJewelzGame = true;
          break;
        case COLLECT:
          this.displayCollectGame = true;
          break;
        case PUZZLE:
          this.displayPuzzleGame = true;
          break;
        default:
          console.log("unknown game", game);
      }
      this.closeGameMenu();
    },

    async receivePresent() {
      this.userStore.monsters = await getMonsterData(this.userStore)
      this.activeMonster = this.userStore.monsters.find(m => m.birthday === this.activeMonster.birthday)

      let lastVisit = this.activeMonster.lastVisit
      this.countdownDifference = 3600000 - (Date.now() - new Date(lastVisit))
      if (this.countdownDifference <= 0) {
        this.showPresentImg = true // show larger present image in center of screen
      } else {
        // convert to nanos
        this.countdownDifference /= 1000

        this.countdownInterval2 = setInterval(() => {
          this.countdownDifference--
          let seconds = Math.floor(this.countdownDifference / 1) % 60
          let minutes = Math.floor(this.countdownDifference / 60) % 60
          this.countdownTimer = `${minutes}:${seconds}`

          if (this.countdownDifference <= 0) {
            clearInterval(this.countdownInterval2)
          }
        }, 1000)
      }

    },

    openPresent() {
      let presentImage = document.getElementById('present-img')
      presentImage.animate([
        { transform: `rotateY(0deg)` },
        { transform: `rotateY(180deg)` },
      ],
        {
          delay: 0,
          duration: 1000,
          iterations: 1
        })

      document.getElementById('present-btn').style.disabled = true

      let rewardId = Math.floor(Math.random() * 100) % 6

      setTimeout(() => {
        document.getElementById('amount').classList.add('visible')

        let rewardSound = new Audio()
        rewardSound.src = require('@/assets/sounds/reward.wav')
        rewardSound.volume = 0.1
        rewardSound.play()

        setTimeout(() => {
          document.getElementById('amount').classList.remove('visible')
        }, 2000)
      }, 1000)

      if (rewardId === 0) {
        let amount = Math.floor(Math.random() * 100)
        this.userStore.money += amount

        document.getElementById('amount').textContent = 'x' + amount

        persistMoneyState(this.userStore)
      } else {
        document.getElementById('amount').textContent = 'x' + 1

        let food = this.userStore.items.food.find(f => f.id === rewardId)
        if (food === undefined) {
          food = {
            id: rewardId,
            quantity: 1
          }
          this.userStore.items.food.push(food)
        } else {
          food.quantity++
        }

        persistItemsState(this.userStore)
      }

      setTimeout(() => {
        let url
        if (rewardId === 0) {
          url = require(`@/assets/misc/coin.png`)
        } else {
          url = require(`@/assets/food/${rewardId}.png`)
        }
        presentImage.style.backgroundImage = "url(" + `${url}` + ")"
        presentImage.style.transform = 'rotateY(180deg)'
      }, 500)

      setTimeout(() => {
        this.showPresentImg = false
      }, 3000);

      this.countdownTimer = '60:00'

      // set lastVisit to Date.now()
      let lastVisit = Date.now()
      this.activeMonster.lastVisit = lastVisit
      persistMonsterState(this.userStore);

      this.countdownDifference = 3600000 - (Date.now() - new Date(lastVisit))

      // convert to nanos
      this.countdownDifference /= 1000

      this.countdownInterval = setInterval(() => {
        this.countdownDifference--
        let seconds = Math.floor(this.countdownDifference / 1) % 60
        let minutes = Math.floor(this.countdownDifference / 60) % 60
        this.countdownTimer = `${minutes}:${seconds}`

        if (this.countdownDifference <= 0) {
          clearInterval(this.countdownInterval)
        }
      }, 1000)

    },

    closeGame() {
      this.displayMemoryGame = false
      this.displayJewelzGame = false
      this.displayCollectGame = false
      this.displayPuzzleGame = false
    }
  },
  computed: {
    selectedMonster() {
      let monsterId = parseInt(window.localStorage.getItem("monsterId")) + 1
      if (monsterId < 10) {
        return require('@/assets/monsters/monster00' + monsterId + '/monster00' + monsterId + '.gif')
      } else {
        return require('@/assets/monsters/monster0' + monsterId + '/monster0' + monsterId + '.gif')
      }
    },
    selectedBackground() {
      return this.assetsStore.backgrounds[window.localStorage.getItem("platformId")];
    },
  },

}
</script>

<style scoped lang="scss">
#menu-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;

  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

#back-btn,
#close {
  align-self: flex-start;

  background-image: url('@/assets/misc/arrow-left.png');
  background-size: cover;
  width: 50px;
  height: 50px;
}

#close {
  background-image: url('@/assets/misc/close.png');
  background-size: cover;
  width: 25px;
  height: 25px;
  margin: 1rem;
}

#timer {
  align-self: center;
  justify-self: flex-end;

  font-weight: bold;
}

#present-btn {
  background-image: url('@/assets/misc/present.png');
  background-size: cover;
  width: 50px;
  height: 50px;
}

#present-img {
  background-image: url('@/assets/misc/present.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  display: none
}

#present-img.visible {
  width: 50px;
  height: 50px;
  display: initial;

  position: absolute;

  animation: movePresent 3s forwards;

  top: calc(50% - 25px);
  left: calc(50% - 25px);
}

#amount {
  display: none;
}

#amount.visible {
  display: initial;

  background-color: rgba($color: #fff, $alpha: .5);
  border-radius: 25px;
  padding: 1rem 2rem;

  position: absolute;

  top: calc(50% - 7rem);
  transform: translateX(-50%);

  font-size: xx-large;
  font-weight: bolder;

  z-index: 5;
}

@keyframes movePresent {
  100% {
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    width: 200px;
    height: 200px;
  }
}

@-webkit-keyframes movePresent {
  100% {
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    width: 200px;
    height: 200px;
  }
}

:is(#love-img, #replete-img, #happiness-img).animate {
  animation: blinkingArrow 3s infinite;
}

@keyframes blinkingArrow {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blinkingArrow {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

#menu {
  position: relative;
  bottom: 50px;
}

#feed {
  align-self: flex-start;

  background-image: url('@/assets/menu/feed.png');
  background-size: cover;
  width: 50px;
  height: 50px;
}

#play {
  align-self: flex-start;

  background-image: url('@/assets/menu/play.png');
  background-size: cover;
  width: 50px;
  height: 50px;

  margin-left: 1rem;
}

#monster-view-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

#monster-area {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 50vw;
  align-self: center;
}

#monster {
  width: 400px;
  min-width: 250px;
  min-height: 250px;
  max-width: 100%;
  max-height: 100%;
  touch-action: pan-x pan-y;
}

#name-wrapper {
  display: flex;
  justify-content: center;
}

#name {
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid yellow;
  height: 2rem;
  padding: 0.5rem 0.5rem;
  border-radius: 25px;

  color: white;
  font-size: 1.5rem;

  width: 400px;
}

#states {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

#food-menu,
#game-menu,
#memory-game,
#puzzle-game {
  display: none;

  width: 80vw;
  min-width: 150px;
  transform: translate(-50%, -50%);
  height: 80vh;
  background: radial-gradient(var(--yellow-light), var(--yellow-dark));
  border: 1px solid black;

  border-radius: 25px;

  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2;
}

:is(#food-menu, #game-menu).visible {

  display: flex;
  flex-direction: column;

  #games {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, max-content));
    grid-row-gap: .5em;
    grid-column-gap: 1rem;
    justify-content: center;
    padding: 0 1rem;
  }

  #foods {
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    justify-content: center;
    margin: 0 1rem;
    overflow-y: auto;
  }
}

:is(#memory-game, #puzzle-game).visible {
  display: flex;
  flex-direction: column;
}

.food {
  width: 150px;
}

.food:last-child {

  hr {
    display: none;
  }

  margin-bottom: 1rem;
}

.food-description {
  width: 150px;
  margin-top: -1rem;
}

.state-improvement-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;

  .state-improvements {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1rem;
  }

}

.food-btn {
  background-size: cover;
  width: 100px;
  height: 100px;

  border: 1px solid black;
  border-radius: 25px;
  margin: 1rem;
}

.game-btn {
  background-size: cover;
  width: 100px;
  height: 100px;

  margin: 1rem;
}

.quantity-pseudo::before {
  content: attr(data-count);
  position: relative;
  bottom: 30px;
  right: -25px;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: red;
  color: white;
  padding: 2px;
}

@media (min-width: 275px) {

  .food,
  .food-description {
    width: 175px;
  }

  .quantity-pseudo::before {
    bottom: 30px;
    right: -40px;
  }
}

@media (min-width: 330px) {

  .food,
  .food-description {
    width: 200px;
  }

  .quantity-pseudo::before {
    bottom: 30px;
    right: -50px;
  }
}

@media (max-width: 375px) {
  #name {
    width: 250px;
  }

  #monster {
    width: 250px;
    height: 250px;
  }
}

@media (min-width: 400px) {

  .food,
  .food-description {
    width: 275px;
  }

  .quantity-pseudo::before {
    bottom: 30px;
    right: -90px;
  }

  #food-menu,
  #game-menu,
  #memory-game,
  #puzzle-game {
    width: 300px;
  }
}

@media (min-width: 500px) {

  .food,
  .food-description {
    width: 350px;
  }

  .quantity-pseudo::before {
    bottom: 30px;
    right: -130px;
  }

  #food-menu,
  #game-menu,
  #memory-game,
  #puzzle-game {
    width: 400px;
  }
}

@media (min-width: 650px) {
  .food-btn {
    grid-area: food-btn;
  }

  .food-description {
    grid-area: food-description;
    margin-top: 0;
    align-self: center;
    width: 300px;
  }

  .state-improvement-wrapper {
    grid-area: state-improvement;
    width: 400px;
    justify-self: center;
    margin-top: 0;
  }

  .food {
    display: grid;
    grid-template-areas:
      "food-btn food-description"
      "state-improvement state-improvement"
      "line line";
    width: 450px;
    padding: 0 1rem;
  }

  hr {
    grid-area: line;
    width: 450px;
  }

  .quantity-pseudo::before {
    bottom: 30px;
    right: -180px;
  }

  #food-menu,
  #game-menu,
  #memory-game,
  #puzzle-game {
    width: 500px;
  }
}
</style>