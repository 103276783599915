<template>
  <div class="content">
    <div id="menu-container">

      <div id="coins">
        <img id="coin-img" src="@/assets/misc/coin.png" alt="Your monster money" width="50" height="50" />
        <label id="coin-label" for="coin-img">{{ userStore.money }}</label>
      </div>

      <div id="shop">
        <button id="shop-btn" class="menu-btn image-button-small" @click="redirectToShop()"></button>
      </div>

      <div id="messages">
        <button id="messages-btn" class="menu-btn image-button-small" @click="redirectToMessages()"></button>
        <div id="messages-pseudo" :data-count="messages.length - userStore.messages.length"
          v-if="(messages.length - userStore.messages.length) > 0">
        </div>
      </div>

      <div id="profile">
        <button id="profile-btn" class="menu-btn image-button-small" @click="redirectToProfile()"></button>
      </div>

      <div id="sign-out">
        <button id="sign-out-btn" class="menu-btn image-button-small" @click="signOut()"></button>
      </div>

    </div>

    <div id="platform-container">
      <div style="margin: auto;">
        <!-- <div style="display: flex; flex-wrap: wrap; flex-direction: row;"> -->
        <!-- :style="{ backgroundImage: 'url(' + platforms[monster.platformId] + ')' }"> -->
        <div id="outer-monster-platform-wrapper">
          <div v-for="monster in userStore.monsters" :key="monster.birthday" class="monster-platform-wrapper"
            @click="openMonsterView(monster)">
            <img class="platform" :src="assetsStore.platforms[monster.platformId]" :alt="monster.name" />
            <img class="monster" :src="assetsStore.monsters[monster.monsterId]" :alt="monster.name">
            <label class="name-label">{{ monster.name }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/user'
import { useAssetsStore } from '@/stores/assets'
import { retrieveMessages } from '@/firebase/db'
import { ref } from 'vue'
import { getAuth, signOut } from "firebase/auth"

export default {

  data() {
    return {
      assetsStore: useAssetsStore(),
    }
  },

  setup() {
    const messages = ref({});

    let loadMessages = async () => {
      messages.value = await retrieveMessages()
    }

    loadMessages()

    return {
      userStore: useUserStore(),
      messages,
    }
  },

  methods: {
    openMonsterView(monster) {
      window.localStorage.setItem('monsterId', monster.monsterId)
      window.localStorage.setItem('platformId', monster.platformId)

      this.$router.push({ name: 'monster', params: { monsterId: monster.birthday } })
    },

    redirectToMessages() {
      this.$router.push('/messages')
    },

    redirectToProfile() {
      this.$router.push('/profile')
    },

    redirectToShop() {
      this.$router.push('/shop')
    },

    signOut() {
      const auth = getAuth();
      signOut(auth).then(() => {
        console.log('Sign out successful')
        setTimeout(() => {
          // TODO: Show popup that sign out was successful
        }, 3000);
        this.$router.push('/')
      }).catch((error) => {
        // TODO: Show popup that sign out was not successful
        console.log('Sign out failed', error)
      });
    },
  }

}
</script>

<style scoped lang="scss">
#menu-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

#messages-btn {
  background-image: url('@/assets/misc/mail.png');
  background-size: cover;
  width: 50px;
  height: 50px;
}

#profile-btn {
  background-image: url('@/assets/misc/profile.png');
  background-size: cover;
  width: 50px;
  height: 50px;
}

#shop-btn {
  background-image: url('@/assets/misc/shop.png');
  background-size: cover;
  width: 50px;
  height: 50px;
}

#sign-out-btn {
  background-image: url('@/assets/misc/sign-out.png');
  background-size: cover;
  width: 50px;
  height: 50px;
}

#messages,
#profile,
#coins,
#shop,
#sign-out {
  margin: 0rem 0.3rem;
}

#coins {
  display: flex;
  flex-direction: column;
  position: relative;
}

#coin-label {
  position: relative;
  top: -0.5rem;
}

#messages {
  position: relative;
}

#messages-pseudo::before {
  content: attr(data-count);
  position: absolute;
  bottom: 0.75rem;
  right: -0.25em;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: red;
  color: white;
}

#platform-container {
  margin-bottom: 100px;
  /*overflow-y: auto;
  height: calc(100vh - 150px);*/
}

#outer-monster-platform-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, max-content));
  grid-row-gap: 25px;
  grid-column-gap: 1rem;
  justify-content: center;
  padding: 0 1rem;
}

.monster-platform-wrapper {
  height: 250px;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: scale(1.04);
  }
}

.platform {
  width: 100%;
  height: auto;
  max-width: 250px;
  z-index: 1;
  align-self: center;
}

.monster {
  width: 100%;
  height: auto;
  z-index: 2;
  position: relative;
  top: calc(-50% - 75px);
  margin: auto;
  max-width: 100px;
  align-self: center;
}

.name-label {
  z-index: 3;
  position: relative;
  top: -120px;
}

@media (min-width: 330px) {

  #messages,
  #profile,
  #coins,
  #shop,
  #sign-out {
    margin: 0.5rem 0.3rem;
  }
}
</style>