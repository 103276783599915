<template>
  <div id="main">

    <div id="text-upper">
      <p>{{ $t('landingPage.p1') }}</p>
      <p>{{ $t('landingPage.p2') }}</p>
      <p>{{ $t('landingPage.p3') }}</p>
    </div>

    <div id="monsters-one">
        <img class="monster" src="@/assets/monsters/monster001/monster001.gif" alt="" width="50px" height="50px" />
        <img class="monster" src="@/assets/monsters/monster002/monster002.gif" alt="" width="50px" height="50px" />
        <img class="monster" src="@/assets/monsters/monster003/monster003.gif" alt="" width="50px" height="50px" />
        <img class="monster" src="@/assets/monsters/monster004/monster004.gif" alt="" width="50px" height="50px" />
        <img class="monster" src="@/assets/monsters/monster005/monster005.gif" alt="" width="50px" height="50px" />
      </div>
    
    <div id="title">
      <h1>{{ $t('landingPage.h1') }}</h1>
      <h2>{{ $t('landingPage.h2') }}</h2>
    </div>

    <div id="monsters-two">
        <img class="monster" src="@/assets/monsters/monster006/monster006.gif" alt="" width="50px" height="50px" />
        <img class="monster" src="@/assets/monsters/monster007/monster007.gif" alt="" width="50px" height="50px" />
        <img class="monster" src="@/assets/monsters/monster008/monster008.gif" alt="" width="50px" height="50px" />
        <img class="monster" src="@/assets/monsters/monster009/monster009.gif" alt="" width="50px" height="50px" />
        <img class="monster" src="@/assets/monsters/monster010/monster010.gif" alt="" width="50px" height="50px" />
      </div>

    <div id="text-lower">
      <p>{{ $t('landingPage.p4') }}</p>
      <p>{{ $t('landingPage.p5') }}</p>
      <p>{{ $t('landingPage.p6') }}</p>
    </div>

    <!--
    <div id="sign-up" style="margin-bottom: 100px;">
      <router-link :to="{ name: 'subscribe' }">{{ $t('landingPage.signUp1') }}</router-link>{{ $t('landingPage.signUp2') }}
    </div>
    -->

  </div>
</template>

<script>
export default {
  name: "LandingPage",

};
</script>

<style lang="scss" scoped>
#main {
  margin-left: 1rem;
  margin-right: 1rem;
//  padding: 2rem 4rem;

  display: flex;
  flex-direction: column;
  
  padding-bottom: 1rem;

/*
  #monster-top-bottom-right {
    position: relative;
    bottom: -50px;
    right: 90%;
  }
  */
}

#text-upper {
  padding-top: 1rem;
}


#monsters-one, #monsters-two {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.monster {
  width: 50px;
  height: 50px;
}

@media (min-width: 530px) {
  .monster {
    width: 75px;
    height: 75px;
  }
}

@media (min-width: 800px) {
  .monster {
    width: 100px;
    height: 100px;
  }
}
</style>
