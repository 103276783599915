<template>
  <button class="card" :class="{ found: pairFound, flipped: flipped}" @click="flipCard()" :style="{ backgroundImage: 'url(' + displayCard() + ')' }"></button>
</template>

<script>
export default {
  props: [ "cardId", "idx" ],
  expose: [ "flipBack" ],

  data() {
    return {
      discovered: false,
      flipped: false,
      pairFound: false
    }
  },

  created() {
    // console.log('cardId', this.cardId, 'idx', this.idx)
  },

  methods: {
    displayCard() {
      if (this.discovered) {
        return require(`@/assets/memory/${this.cardId}.png`)
      }

      return require(`@/assets/memory/back.png`)
    },

    flipCard() {
      if (this.discovered) {
        return
      }

      this.discovered = true;
      this.flipped = true;
      this.$emit('flipped', this.cardId, this.idx, this)
      
    },

    flipBack() {
      this.discovered = false;
      this.flipped = false;
    },

    markAsPairFound() {
      this.flipped = false
      this.pairFound = true
      setTimeout(() => {
        this.pairFound = false
      }, 1000);
    },

  }

}
</script>

<style>
.card {
  background-size: cover;
  width: 50px;
  height: 50px;
}

.flipped {
  border: 5px solid var(--main-color);
  border-radius: 5px;
}

.found {
  border: 5px solid hsl(166, 71%, 48%);
  border-radius: 5px;
}
</style>