<template>
  <div class="wrapper" style="text-align: left; margin-bottom: 1.5rem;">

    <div id="menu-container">
      <button id="back-btn" class="menu-btn image-button-small" @click="redirectToOverview()"></button>
    </div>

    <div id="container" v-if="['de', 'at', 'ch'].includes($i18n.locale)">
      <h1>Datenschutz&shy;erkl&auml;rung</h1>
      <h2>1. Datenschutz auf einen Blick</h2>
      <h3>Allgemeine Hinweise</h3>
      <p>Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren personenbezogenen Daten
        passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich
        identifiziert werden k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer
        unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.</p>
      <h3>Datenerfassung auf dieser Website</h3>
      <h4>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</h4>
      <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen
        Sie dem Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser Datenschutzerkl&auml;rung
        entnehmen.</p>
      <h4>Wie erfassen wir Ihre Daten?</h4>
      <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um
        Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
      <p>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme
        erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des
        Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.</p>
      <h4>Wof&uuml;r nutzen wir Ihre Daten?</h4>
      <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten. Andere
        Daten k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>
      <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4>
      <p>Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck Ihrer
        gespeicherten personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder
        L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
        k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das
        Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.</p>
      <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns wenden.</p>
      <h2>2. Hosting</h2>
      <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
      <h3>Externes Hosting</h3>
      <p>Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden
        auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich v.&nbsp;a. um IP-Adressen,
        Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
        Daten, die &uuml;ber eine Website generiert werden, handeln.</p>
      <p>Das externe Hosting erfolgt zum Zwecke der Vertragserf&uuml;llung gegen&uuml;ber unseren potenziellen und
        bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
        Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern
        eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von
        Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder
        den Zugriff auf Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG
        umfasst. Die Einwilligung ist jederzeit widerrufbar.</p>
      <p>Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erf&uuml;llung seiner
        Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.</p>
      <p>Wir setzen folgende(n) Hoster ein:</p>
      <p>Google Domains<br />
        https://domains.google.com</p>
      <h2>3. Allgemeine Hinweise und Pflicht&shy;informationen</h2>
      <h3>Datenschutz</h3>
      <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre
        personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerkl&auml;rung.</p>
      <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
        sind Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende
        Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und wof&uuml;r wir sie nutzen. Sie
        erl&auml;utert auch, wie und zu welchem Zweck das geschieht.</p>
      <p>Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der Kommunikation per E-Mail)
        Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist
        nicht m&ouml;glich.</p>
      <h3>Hinweis zur verantwortlichen Stelle</h3>
      <p>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:</p>
      <p>Patricia Sauer<br />
        Tannenweg 3<br />
        16540 Hohen Neuendorf</p>

      <p>Telefon: +49 (0) 171 170 94 26<br />
        E-Mail: patricia@patricia-sauer.com</p>
      <p>Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen
        &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen
        o. &Auml;.) entscheidet.</p>

      <h3>Speicherdauer</h3>
      <p>Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere Speicherdauer genannt wurde, verbleiben
        Ihre personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt. Wenn Sie ein
        berechtigtes L&ouml;schersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden
        Ihre Daten gel&ouml;scht, sofern wir keine anderen rechtlich zul&auml;ssigen Gr&uuml;nde f&uuml;r die
        Speicherung Ihrer personenbezogenen Daten haben (z.&nbsp;B. steuer- oder handelsrechtliche
        Aufbewahrungsfristen); im letztgenannten Fall erfolgt die L&ouml;schung nach Fortfall dieser Gr&uuml;nde.</p>
      <h3>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h3>
      <p>Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
        Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach
        Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdr&uuml;cklichen Einwilligung in die &Uuml;bertragung
        personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung au&szlig;erdem auf Grundlage von Art. 49
        Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr
        Endger&auml;t (z.&nbsp;B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung
        zus&auml;tzlich auf Grundlage von &sect; 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre
        Daten zur Vertragserf&uuml;llung oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich,
        verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre
        Daten, sofern diese zur Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art.
        6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art.
        6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber die jeweils im Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in
        den folgenden Abs&auml;tzen dieser Datenschutzerkl&auml;rung informiert.</p>
      <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
      <p>Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie
        k&ouml;nnen eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtm&auml;&szlig;igkeit der bis zum
        Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.</p>
      <h3>Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)
      </h3>
      <p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS
        RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
        PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF DIESE BESTIMMUNGEN
        GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
        DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN
        DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE
        VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT
        DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1
        DSGVO).</p>
      <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT,
        JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER
        WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG
        STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
        DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</p>
      <h3>Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde</h3>
      <p>Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
        Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres
        Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht unbeschadet
        anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>
      <h3>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</h3>
      <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags
        automatisiert verarbeiten, an sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format
        aush&auml;ndigen zu lassen. Sofern Sie die direkte &Uuml;bertragung der Daten an einen anderen Verantwortlichen
        verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
      <h3>Auskunft, L&ouml;schung und Berichtigung</h3>
      <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft
        &uuml;ber Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu sowie zu weiteren
        Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.</p>
      <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>
      <p>Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu
        k&ouml;nnen Sie sich jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in
        folgenden F&auml;llen:</p>
      <ul>
        <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tigen wir
          in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das Recht,
          die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
        <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen
          Sie statt der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.</li>
        <li>Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung,
          Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der
          L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
        <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung zwischen Ihren
          und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen &uuml;berwiegen,
          haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
      </ul>
      <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten
        &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung,
        Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
        nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der
        Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.</p>
      <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3>
      <p>Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie
        zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
        TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass die Adresszeile des
        Browsers von &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer
        Browserzeile.</p>
      <p>Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns
        &uuml;bermitteln, nicht von Dritten mitgelesen werden.</p>
      <h3>Widerspruch gegen Werbe-E-Mails</h3>
      <p>Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von
        nicht ausdr&uuml;cklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die
        Betreiber der Seiten behalten sich ausdr&uuml;cklich rechtliche Schritte im Falle der unverlangten Zusendung von
        Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>
      <h2>4. Datenerfassung auf dieser Website</h2>
      <h3>Cookies</h3>
      <p>Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;. Cookies sind kleine Datenpakete und richten
        auf Ihrem Endger&auml;t keinen Schaden an. Sie werden entweder vor&uuml;bergehend f&uuml;r die Dauer einer
        Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endger&auml;t gespeichert.
        Session-Cookies werden nach Ende Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies bleiben auf Ihrem
        Endger&auml;t gespeichert, bis Sie diese selbst l&ouml;schen&nbsp;oder eine automatische L&ouml;schung durch
        Ihren Webbrowser erfolgt.</p>
      <p>Teilweise k&ouml;nnen auch Cookies von Drittunternehmen auf Ihrem Endger&auml;t gespeichert werden, wenn Sie
        unsere Seite betreten (Third-Party-Cookies). Diese erm&ouml;glichen uns oder Ihnen die Nutzung bestimmter
        Dienstleistungen des Drittunternehmens (z.&nbsp;B. Cookies zur Abwicklung von Zahlungsdienstleistungen).</p>
      <p>Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte
        Websitefunktionen ohne diese nicht funktionieren w&uuml;rden (z.&nbsp;B. die Warenkorbfunktion oder die Anzeige
        von Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten&nbsp;oder Werbung anzuzeigen.</p>
      <p>Cookies, die zur Durchf&uuml;hrung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter,
        von Ihnen erw&uuml;nschter Funktionen (z.&nbsp;B. f&uuml;r die Warenkorbfunktion) oder zur Optimierung der
        Website (z.&nbsp;B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf
        Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der
        Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies
        und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich
        auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG); die Einwilligung ist
        jederzeit widerrufbar.</p>
      <p>Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und
        Cookies nur im Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell
        ausschlie&szlig;en sowie das automatische L&ouml;schen der Cookies beim Schlie&szlig;en des Browsers aktivieren.
        Bei der Deaktivierung von Cookies kann die Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.</p>
      <p>Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hier&uuml;ber im
        Rahmen dieser Datenschutzerkl&auml;rung gesondert informieren und ggf. eine Einwilligung abfragen.</p>
      <h3>Server-Log-Dateien</h3>
      <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die
        Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:</p>
      <ul>
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
        <li>IP-Adresse</li>
      </ul>
      <p>Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>
      <p>Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
        berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website &ndash;
        hierzu m&uuml;ssen die Server-Log-Files erfasst werden.</p>
      <h3>Kontaktformular</h3>
      <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
        inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
      <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der
        Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
        erforderlich ist. In allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse
        an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit
        widerrufbar.</p>
      <p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung
        auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung
        entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen
        &ndash; insbesondere Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.</p>
      <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>
      <p>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
        hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
        gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
      <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der
        Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen
        erforderlich ist. In allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem berechtigten Interesse
        an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit
        widerrufbar.</p>
      <p>Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis Sie uns zur
        L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die
        Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
        gesetzliche Bestimmungen &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
      </p>
      <h3>Registrierung auf dieser Website</h3>
      <p>Sie k&ouml;nnen sich auf dieser Website registrieren, um zus&auml;tzliche Funktionen auf der Seite zu nutzen.
        Die dazu eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes,
        f&uuml;r den Sie sich registriert haben. Die bei der Registrierung abgefragten Pflichtangaben m&uuml;ssen
        vollst&auml;ndig angegeben werden. Anderenfalls werden wir die Registrierung ablehnen.</p>
      <p>F&uuml;r wichtige &Auml;nderungen etwa beim Angebotsumfang oder bei technisch notwendigen &Auml;nderungen
        nutzen wir die bei der Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.</p>
      <p>Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt zum Zwecke der Durchf&uuml;hrung des
        durch die Registrierung begr&uuml;ndeten Nutzungsverh&auml;ltnisses und ggf. zur Anbahnung weiterer
        Vertr&auml;ge (Art. 6 Abs. 1 lit. b DSGVO).</p>
      <p>Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf dieser Website
        registriert sind und werden anschlie&szlig;end gel&ouml;scht. Gesetzliche Aufbewahrungsfristen bleiben
        unber&uuml;hrt.</p>
      <h2>5. Newsletter</h2>
      <h3>Newsletter&shy;daten</h3>
      <p>Wenn Sie den auf der Website angebotenen Newsletter beziehen m&ouml;chten, ben&ouml;tigen wir von Ihnen eine
        E-Mail-Adresse sowie Informationen, welche uns die &Uuml;berpr&uuml;fung gestatten, dass Sie der Inhaber der
        angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden
        nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschlie&szlig;lich f&uuml;r den
        Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.</p>
      <p>Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschlie&szlig;lich auf
        Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten,
        der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters k&ouml;nnen Sie jederzeit widerrufen, etwa
        &uuml;ber den &bdquo;Austragen&ldquo;-Link im Newsletter. Die Rechtm&auml;&szlig;igkeit der bereits erfolgten
        Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.</p>
      <p>Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer
        Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung
        des Newsletters oder nach Zweckfortfall aus der Newsletterverteilerliste gel&ouml;scht. Wir behalten uns vor,
        E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen im Rahmen unseres berechtigten Interesses
        nach Art. 6 Abs. 1 lit. f DSGVO zu l&ouml;schen oder zu sperren.</p>
      <p>Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben hiervon unber&uuml;hrt.</p>
      <p>Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem
        Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, sofern dies zur Verhinderung k&uuml;nftiger
        Mailings erforderlich ist. Die Daten aus der Blacklist werden nur f&uuml;r diesen Zweck verwendet und nicht mit
        anderen Daten zusammengef&uuml;hrt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der
        Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6
        Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet. <strong>Sie k&ouml;nnen der
          Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse &uuml;berwiegen.</strong></p>
    </div>

    <div id="container" v-else>
      <h1>Privacy Policy</h1>
      <h2>1. An overview of data protection</h2>
      <h3>General information</h3>
      <p>The following information will provide you with an easy to navigate overview of what will happen with your
        personal data when you visit this website. The term &ldquo;personal data&rdquo; comprises all data that can be
        used to personally identify you. For detailed information about the subject matter of data protection, please
        consult our Data Protection Declaration, which we have included beneath this copy.</p>
      <h3>Data recording on this website</h3>
      <h4>Who is the responsible party for the recording of data on this website (i.e., the &ldquo;controller&rdquo;)?
      </h4>
      <p>The data on this website is processed by the operator of the website, whose contact information is available
        under section &ldquo;Information about the responsible party (referred to as the &ldquo;controller&rdquo; in the
        GDPR)&rdquo; in this Privacy Policy.</p>
      <h4>How do we record your data?</h4>
      <p>We collect your data as a result of your sharing of your data with us. This may, for instance be information
        you enter into our contact form.</p>
      <p>Other data shall be recorded by our IT systems automatically or after you consent to its recording during your
        website visit. This data comprises primarily technical information (e.g., web browser, operating system, or time
        the site was accessed). This information is recorded automatically when you access this website.</p>
      <h4>What are the purposes we use your data for?</h4>
      <p>A portion of the information is generated to guarantee the error free provision of the website. Other data may
        be used to analyze your user patterns.</p>
      <h4>What rights do you have as far as your information is concerned?</h4>
      <p>You have the right to receive information about the source, recipients, and purposes of your archived personal
        data at any time without having to pay a fee for such disclosures. You also have the right to demand that your
        data are rectified or eradicated. If you have consented to data processing, you have the option to revoke this
        consent at any time, which shall affect all future data processing. Moreover, you have the right to demand that
        the processing of your data be restricted under certain circumstances. Furthermore, you have the right to log a
        complaint with the competent supervising agency.</p>
      <p>Please do not hesitate to contact us at any time if you have questions about this or any other data protection
        related issues.</p>
      <h2>2. Hosting</h2>
      <p>We are hosting the content of our website at the following provider:</p>
      <h3>External Hosting</h3>
      <p>This website is hosted externally. Personal data collected on this website are stored on the servers of the
        host. These may include, but are not limited to, IP addresses, contact requests, metadata and communications,
        contract information, contact information, names, web page access, and other data generated through a web site.
      </p>
      <p>The external hosting serves the purpose of fulfilling the contract with our potential and existing customers
        (Art. 6(1)(b) GDPR) and in the interest of secure, fast, and efficient provision of our online services by a
        professional provider (Art. 6(1)(f) GDPR). If appropriate consent has been obtained, the processing is carried
        out exclusively on the basis of Art. 6 (1)(a) GDPR and &sect; 25 (1) TTDSG, insofar the consent includes the
        storage of cookies or the access to information in the user's end device (e.g., device fingerprinting) within
        the meaning of the TTDSG. This consent can be revoked at any time.</p>
      <p>Our host(s) will only process your data to the extent necessary to fulfil its performance obligations and to
        follow our instructions with respect to such data.</p>
      <p>We are using the following host(s):</p>
      <p>Google Domains<br />
        https://domains.google.com</p>
      <h2>3. General information and mandatory information</h2>
      <h3>Data protection</h3>
      <p>The operators of this website and its pages take the protection of your personal data very seriously. Hence, we
        handle your personal data as confidential information and in compliance with the statutory data protection
        regulations and this Data Protection Declaration.</p>
      <p>Whenever you use this website, a variety of personal information will be collected. Personal data comprises
        data that can be used to personally identify you. This Data Protection Declaration explains which data we
        collect as well as the purposes we use this data for. It also explains how, and for which purpose the
        information is collected.</p>
      <p>We herewith advise you that the transmission of data via the Internet (i.e., through e-mail communications) may
        be prone to security gaps. It is not possible to completely protect data against third-party access.</p>
      <h3>Information about the responsible party (referred to as the &ldquo;controller&rdquo; in the GDPR)</h3>
      <p>The data processing controller on this website is:</p>
      <p>Patricia Sauer<br />
        Tannenweg 3<br />
        16540 Hohen Neuendorf</p>

      <p>Phone: 0171 170 94 26<br />
        E-mail: patricia@patricia-sauer.com</p>
      <p>The controller is the natural person or legal entity that single-handedly or jointly with others makes
        decisions as to the purposes of and resources for the processing of personal data (e.g., names, e-mail
        addresses, etc.).</p>

      <h3>Storage duration</h3>
      <p>Unless a more specific storage period has been specified in this privacy policy, your personal data will remain
        with us until the purpose for which it was collected no longer applies. If you assert a justified request for
        deletion or revoke your consent to data processing, your data will be deleted, unless we have other legally
        permissible reasons for storing your personal data (e.g., tax or commercial law retention periods); in the
        latter case, the deletion will take place after these reasons cease to apply.</p>
      <h3>General information on the legal basis for the data processing on this website</h3>
      <p>If you have consented to data processing, we process your personal data on the basis of Art. 6(1)(a) GDPR or
        Art. 9 (2)(a) GDPR, if special categories of data are processed according to Art. 9 (1) DSGVO. In the case of
        explicit consent to the transfer of personal data to third countries, the data processing is also based on Art.
        49 (1)(a) GDPR. If you have consented to the storage of cookies or to the access to information in your end
        device (e.g., via device fingerprinting), the data processing is additionally based on &sect; 25 (1) TTDSG. The
        consent can be revoked at any time. If your data is required for the fulfillment of a contract or for the
        implementation of pre-contractual measures, we process your data on the basis of Art. 6(1)(b) GDPR. Furthermore,
        if your data is required for the fulfillment of a legal obligation, we process it on the basis of Art. 6(1)(c)
        GDPR. Furthermore, the data processing may be carried out on the basis of our legitimate interest according to
        Art. 6(1)(f) GDPR. Information on the relevant legal basis in each individual case is provided in the following
        paragraphs of this privacy policy.</p>
      <h3>Revocation of your consent to the processing of data</h3>
      <p>A wide range of data processing transactions are possible only subject to your express consent. You can also
        revoke at any time any consent you have already given us. This shall be without prejudice to the lawfulness of
        any data collection that occurred prior to your revocation.</p>
      <h3>Right to object to the collection of data in special cases; right to object to direct advertising (Art. 21
        GDPR)</h3>
      <p>IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6(1)(E) OR (F) GDPR, YOU HAVE THE RIGHT TO AT ANY
        TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE SITUATION. THIS
        ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING
        OF DATA IS BASED, PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO LONGER
        PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS
        FOR THE PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE
        PROCESSING IS THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21(1) GDPR).
      </p>
      <p>IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU HAVE THE RIGHT TO OBJECT
        TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING AT ANY TIME. THIS ALSO
        APPLIES TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR
        PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART.
        21(2) GDPR).</p>
      <h3>Right to log a complaint with the competent supervisory agency</h3>
      <p>In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory
        agency, in particular in the member state where they usually maintain their domicile, place of work or at the
        place where the alleged violation occurred. The right to log a complaint is in effect regardless of any other
        administrative or court proceedings available as legal recourses.</p>
      <h3>Right to data portability</h3>
      <p>You have the right to demand that we hand over any data we automatically process on the basis of your consent
        or in order to fulfil a contract be handed over to you or a third party in a commonly used, machine readable
        format. If you should demand the direct transfer of the data to another controller, this will be done only if it
        is technically feasible.</p>
      <h3>Information about, rectification and eradication of data</h3>
      <p>Within the scope of the applicable statutory provisions, you have the right to at any time demand information
        about your archived personal data, their source and recipients as well as the purpose of the processing of your
        data. You may also have a right to have your data rectified or eradicated. If you have questions about this
        subject matter or any other questions about personal data, please do not hesitate to contact us at any time.</p>
      <h3>Right to demand processing restrictions</h3>
      <p>You have the right to demand the imposition of restrictions as far as the processing of your personal data is
        concerned. To do so, you may contact us at any time. The right to demand restriction of processing applies in
        the following cases:</p>
      <ul>
        <li>In the event that you should dispute the correctness of your data archived by us, we will usually need some
          time to verify this claim. During the time that this investigation is ongoing, you have the right to demand
          that we restrict the processing of your personal data.</li>
        <li>If the processing of your personal data was/is conducted in an unlawful manner, you have the option to
          demand the restriction of the processing of your data in lieu of demanding the eradication of this data.</li>
        <li>If we do not need your personal data any longer and you need it to exercise, defend or claim legal
          entitlements, you have the right to demand the restriction of the processing of your personal data instead of
          its eradication.</li>
        <li>If you have raised an objection pursuant to Art. 21(1) GDPR, your rights and our rights will have to be
          weighed against each other. As long as it has not been determined whose interests prevail, you have the right
          to demand a restriction of the processing of your personal data.</li>
      </ul>
      <p>If you have restricted the processing of your personal data, these data &ndash; with the exception of their
        archiving &ndash; may be processed only subject to your consent or to claim, exercise or defend legal
        entitlements or to protect the rights of other natural persons or legal entities or for important public
        interest reasons cited by the European Union or a member state of the EU.</p>
      <h3>SSL and/or TLS encryption</h3>
      <p>For security reasons and to protect the transmission of confidential content, such as purchase orders or
        inquiries you submit to us as the website operator, this website uses either an SSL or a TLS encryption program.
        You can recognize an encrypted connection by checking whether the address line of the browser switches from
        &ldquo;http://&rdquo; to &ldquo;https://&rdquo; and also by the appearance of the lock icon in the browser line.
      </p>
      <p>If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third parties.</p>
      <h3>Rejection of unsolicited e-mails</h3>
      <p>We herewith object to the use of contact information published in conjunction with the mandatory information to
        be provided in our Site Notice to send us promotional and information material that we have not expressly
        requested. The operators of this website and its pages reserve the express right to take legal action in the
        event of the unsolicited sending of promotional information, for instance via SPAM messages.</p>
      <h2>4. Recording of data on this website</h2>
      <h3>Cookies</h3>
      <p>Our websites and pages use what the industry refers to as &ldquo;cookies.&rdquo; Cookies are small data
        packages that do not cause any damage to your device. They are either stored temporarily for the duration of a
        session (session cookies) or they are permanently archived on your device (permanent cookies). Session cookies
        are automatically deleted once you terminate your visit. Permanent cookies remain archived on your device until
        you actively delete them, or they are automatically eradicated by your web browser.</p>
      <p>In some cases, it is possible that third-party cookies are stored on your device once you enter our site
        (third-party cookies). These cookies enable you or us to take advantage of certain services offered by the third
        party (e.g., cookies for the processing of payment services).</p>
      <p>Cookies have a variety of functions. Many cookies are technically essential since certain website functions
        would not work in the absence of the cookies (e.g., the shopping cart function or the display of videos). The
        purpose of other cookies may be the analysis of user patterns or the display of promotional messages.</p>
      <p>Cookies, which are required for the performance of electronic communication transactions, or for the provision
        of certain functions you want to use (e.g., for the shopping cart function) or those that are necessary for the
        optimization (required cookies) of the website (e.g., cookies that provide measurable insights into the web
        audience), shall be stored on the basis of Art. 6(1)(f) GDPR, unless a different legal basis is cited. The
        operator of the website has a legitimate interest in the storage of required cookies to ensure the technically
        error free and optimized provision of the operator&rsquo;s services. If your consent to the storage of the
        cookies and similar recognition technologies has been requested, processing occurs exclusively on the basis of
        the consent obtained (Art. 6(1)(a) GDPR and &sect; 25 (1) TTDSG); this consent may be revoked at any time.</p>
      <p>You have the option to set up your browser in such a manner that you will be notified any time cookies are
        placed and to permit the acceptance of cookies only in specific cases. You may also exclude the acceptance of
        cookies in certain cases or in general or activate the delete function for the automatic eradication of cookies
        when the browser closes. If cookies are deactivated, the functions of this website may be limited.</p>
      <p>In the event that third-party cookies are used or if cookies are used for analytical purposes, we will
        separately notify you in conjunction with this Data Protection Policy and, if applicable, ask for your consent.
      </p>
      <h3>Server log files</h3>
      <p>The provider of this website and its pages automatically collects and stores information in so-called server
        log files, which your browser communicates to us automatically. The information comprises:</p>
      <ul>
        <li>The type and version of browser used</li>
        <li>The used operating system</li>
        <li>Referrer URL</li>
        <li>The hostname of the accessing computer</li>
        <li>The time of the server inquiry</li>
        <li>The IP address</li>
      </ul>
      <p>This data is not merged with other data sources.</p>
      <p>This data is recorded on the basis of Art. 6(1)(f) GDPR. The operator of the website has a legitimate interest
        in the technically error free depiction and the optimization of the operator&rsquo;s website. In order to
        achieve this, server log files must be recorded.</p>
      <h3>Contact form</h3>
      <p>If you submit inquiries to us via our contact form, the information provided in the contact form as well as any
        contact information provided therein will be stored by us in order to handle your inquiry and in the event that
        we have further questions. We will not share this information without your consent.</p>
      <p>The processing of these data is based on Art. 6(1)(b) GDPR, if your request is related to the execution of a
        contract or if it is necessary to carry out pre-contractual measures. In all other cases the processing is based
        on our legitimate interest in the effective processing of the requests addressed to us (Art. 6(1)(f) GDPR) or on
        your agreement (Art. 6(1)(a) GDPR) if this has been requested; the consent can be revoked at any time.</p>
      <p>The information you have entered into the contact form shall remain with us until you ask us to eradicate the
        data, revoke your consent to the archiving of data or if the purpose for which the information is being archived
        no longer exists (e.g., after we have concluded our response to your inquiry). This shall be without prejudice
        to any mandatory legal provisions, in particular retention periods.</p>
      <h3>Request by e-mail, telephone, or fax</h3>
      <p>If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name,
        request) will be stored and processed by us for the purpose of processing your request. We do not pass these
        data on without your consent.</p>
      <p>These data are processed on the basis of Art. 6(1)(b) GDPR if your inquiry is related to the fulfillment of a
        contract or is required for the performance of pre-contractual measures. In all other cases, the data are
        processed on the basis of our legitimate interest in the effective handling of inquiries submitted to us (Art.
        6(1)(f) GDPR) or on the basis of your consent (Art. 6(1)(a) GDPR) if it has been obtained; the consent can be
        revoked at any time.</p>
      <p>The data sent by you to us via contact requests remain with us until you request us to delete, revoke your
        consent to the storage or the purpose for the data storage lapses (e.g. after completion of your request).
        Mandatory statutory provisions - in particular statutory retention periods - remain unaffected.</p>
      <h3>Registration on this website</h3>
      <p>You have the option to register on this website to be able to use additional website functions. We shall use
        the data you enter only for the purpose of using the respective offer or service you have registered for. The
        required information we request at the time of registration must be entered in full. Otherwise, we shall reject
        the registration.</p>
      <p>To notify you of any important changes to the scope of our portfolio or in the event of technical
        modifications, we shall use the e-mail address provided during the registration process.</p>
      <p>We shall process the data entered during the registration process on the basis of your consent (Art. 6(1)(a)
        GDPR).</p>
      <p>The data recorded during the registration process shall be stored by us as long as you are registered on this
        website. Subsequently, such data shall be deleted. This shall be without prejudice to mandatory statutory
        retention obligations.</p>

      <!--
      <h2>5. Newsletter</h2>
      <h3>Newsletter data</h3>
      <p>If you would like to subscribe to the newsletter offered on this website, we will need from you an e-mail
        address as well as information that allow us to verify that you are the owner of the e-mail address provided and
        consent to the receipt of the newsletter. No further data shall be collected or shall be collected only on a
        voluntary basis. We shall use such data only for the sending of the requested information and shall not share
        such data with any third parties.</p>
      <p>The processing of the information entered into the newsletter subscription form shall occur exclusively on the
        basis of your consent (Art. 6(1)(a) GDPR). You may revoke the consent you have given to the archiving of data,
        the e-mail address, and the use of this information for the sending of the newsletter at any time, for instance
        by clicking on the &ldquo;Unsubscribe&rdquo; link in the newsletter. This shall be without prejudice to the
        lawfulness of any data processing transactions that have taken place to date.</p>
      <p>The data deposited with us for the purpose of subscribing to the newsletter will be stored by us until you
        unsubscribe from the newsletter or the newsletter service provider and deleted from the newsletter distribution
        list after you unsubscribe from the newsletter or after the purpose has ceased to apply. We reserve the right to
        delete or block e-mail addresses from our newsletter distribution list at our own discretion within the scope of
        our legitimate interest in accordance with Art. 6(1)(f) GDPR.</p>
      <p>Data stored for other purposes with us remain unaffected.</p>
      <p>After you unsubscribe from the newsletter distribution list, your e-mail address may be stored by us or the
        newsletter service provider in a blacklist, if such action is necessary to prevent future mailings. The data
        from the blacklist is used only for this purpose and not merged with other data. This serves both your interest
        and our interest in complying with the legal requirements when sending newsletters (legitimate interest within
        the meaning of Art. 6(1)(f) GDPR). The storage in the blacklist is indefinite. <strong>You may object to the
          storage if your interests outweigh our legitimate interest.</strong></p>
      -->
    </div>

    <div id="cookie-consent-wrapper">
      <div id="cookie-consent">
        <div id="cookie-consent-text">
          {{ $t('cookieConsent.content') }}
        </div>

        <div id="button-wrapper">
          <button id="decline" @click="setCookieAccepted(false)">{{ $t('cookieConsent.decline') }}</button>
          <button id="accept" @click="setCookieAccepted(true)">{{ $t('cookieConsent.accept') }}</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { useUserStore } from '@/stores/user'

export default {
  data() {
    return {
      userStore: useUserStore(),
    }
  },

  methods: {
    redirectToOverview() {
      if (this.userStore.signedIn) {
        this.$router.push("/monsters");
      } else {
        this.$router.push("/");
      }
    },

    setCookieAccepted(val) {
      window.localStorage.setItem('cookiesAccepted', val.toString())
      this.$emit('cookiesAccepted', val)
    },
  }
}
</script>
  
  <!-- <script>
  import firebase from 'firebase'
  
  export default {
    name: 'App',
    components: {},
    data() {
      return {}
    },
  
    methods: {
      setCookieAccepted(val) {
        window.localStorage.setItem('cookiesAccepted', val)
        if (val === 'false') {
          firebase.auth().signOut()
          this.$router.push('/')
          this.store.user = undefined
        }
      },
    },
  }
  </script> -->
  
<style scoped>
#container {
  background-color: var(--achievement-color);
  overflow-y: auto;
  overflow-x: none;

  height: -webkit-fit-content;
  height: -moz-fit-content;

  border-radius: 16px;

  padding: 0 1rem;

  margin-left: 1rem;
  margin-right: 1rem;
}

#cookie-consent-wrapper {
  display: flex;
  justify-content: center;
}

#cookie-consent {
  z-index: 100;
  margin-bottom: 2rem;

  background: radial-gradient(var(--yellow-light), var(--yellow-dark));
  border: 1px solid black;

  border-radius: 25px;

  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  padding: 2rem;

  -webkit-box-shadow: 0px 0px 8px var(--background-color);
  -moz-box-shadow: 0px 0px 8px var(--background-color);
  box-shadow: 0px 0px 8px var(--background-color);
}

#button-wrapper {
  display: flex;
  justify-content: center;
}

#decline,
#accept {
  font-size: 1rem;
  border-radius: 16px;
  height: 50px;
  margin: 0 1rem;
  text-transform: uppercase;
  letter-spacing: 1.76px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700;
}

#decline {
  color: var(--background-color);
  border: 0.15rem solid var(--btn-color);
  background-color: var(--btn-color);
}

#decline:hover {
  border: 0.15rem solid var(--btn-color-active);
  background-color: var(--btn-color-hover);
}

#decline:active {
  color: var(--btn-color);
  border: 0.15rem solid var(--btn-color-hover);
  background-color: var(--btn-color-active);
}

#accept {
  color: var(--btn-color);
  border: 0.15rem solid var(--background-color);
  background-color: var(--background-color);
}

#accept:hover {
  border: 0.15rem solid var(--background-color-active);
  background-color: var(--background-color-hover);
}

#accept:active {
  color: var(--background-color);
  border: 0.15rem solid var(--background-color-hover);
  background-color: var(--background-color-active);
}

#cookie-consent-text {
  text-align: center;
  margin-bottom: 1rem;
}

@media (max-width: 500px) {
  #cookie-consent {
    padding: 1rem;
    width: 70vw;
  }

  #button-wrapper {
    flex-flow: column-reverse;
    align-content: center;
  }

  #decline {
    margin-top: 1rem;
  }

}
</style>