import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      signedIn: false,
      uid: '',
      id: '',
      messages: [],
      friends: {
        connected: [
          {
            name: 'name1',
            id: 'some id1'
          },
          {
            name: 'name2',
            id: 'some id2'
          }
        ],
        requested: [
          {
            name: 'name3',
            id: 'some id3'
          },
          {
            name: 'name4',
            id: 'some id4'
          }
        ]
      },
      monsters: [],
      money: 0,
      items:
        {
          food: [
            {
              id: 1,
              quantity: 2
            },
            {
              id: 2,
              quantity: 0
            },
            {
              id: 3,
              quantity: 1
            },
            {
              id: 4,
              quantity: 5
            },
            {
              id: 5,
              quantity: 3
            },
          ]
        }
    }
  },

  actions: {
    setUid(uid) {
      this.uid = uid
    }
  },

  getters: {
    getUid: (state) => { return state.uid }
  }
})