<template>
  <div>
    <h1>{{ $t('signUp.title')}}</h1>

    <div>
      
      <div id="form-elements">
        <div id="wrapper_email" class="revue-form-group">
          <label for="member_email" id="label_email">{{ $t('signUp.email')}}</label>
          <input class="revue-form-field" type="email" id="member_email" v-model="email" size="30" @keyup.enter="signUp()">
        </div>
        <div id="wrapper_password" class="revue-form-group">
          <label for="member_password" id="label_password">{{ $t('signUp.password')}}</label>
          <input class="revue-form-field" type="password" id="member_password" v-model="password" size="30" @keyup.enter="signUp()">
        </div>
        <div id="wrapper_password_confirmed" class="revue-form-group">
          <label for="member_password_confirmed" id="label_password_confirmed">{{ $t('signUp.passwordConfirmed')}}</label>
          <input class="revue-form-field" type="password" id="member_password_confirmed" v-model="passwordConfirmed" size="30" @keyup.enter="signUp()">
        </div>
      </div>
      <div>
        <button class="button-large" @click="signUp()">{{ $t('signUp.signUp')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useUserStore } from "@/stores/user";
import { createUser } from '@/firebase/db'

export default {

  data() {
    return {
      store: useUserStore(),
    }
  },

  setup() {   
    return {
      email: "",
      password: "",
      passwordConfirmed: ""
    }
  },

  methods: {
    signUp() {
      if (this.email === "" || this.password === "" || this.passwordConfirmed === "") {
        console.log("either email, password, or confirmed password is null");
        return;
      }

      if (this.password !== this.passwordConfirmed) {
        this.showErrorReason('Passwords are not equal.')
        return;
      }
     
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          this.store = {}
          this.store.signedIn = true
          this.store.uid = user.uid
          createUser(this.store)
          this.$router.push('/create')
        })
        .catch((error) => {
          console.log('error', error)
          const errorCode = error.code;
          const errorMessage = error.message;

          //openErrorModal()

          console.log(errorCode)
          console.log(errorMessage)
          // ..
        });

    },

  },

}
</script>

<style scoped>
#wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
}

#form-elements {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

#label_email, #label_password, #label_password_confirmed {
  text-align: left;
  padding: 1rem 0 0.25rem 0;
}

#wrapper_email, #wrapper_password, #wrapper_password_confirmed{
  display: flex;
  flex-direction: column;
  width: 50vw;
}
</style>