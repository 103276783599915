import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, update, get, child, remove } from "firebase/database";
import { v4 as uuidv4 } from 'uuid';
import { getAuth, deleteUser, signOut } from "firebase/auth";

export const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
  databaseURL: process.env.VUE_APP_DATABASE_URL,
};

const app = initializeApp(firebaseConfig)
export const db = getDatabase(app);

export function createUser(store) {
  set(ref(db, `users/${store.uid}`), {
    id: uuidv4(),
    money: 100,
    isAdmin: false,
    items: {
      food: [
        {
          id: 1,
          quantity: 2
        },
        {
          id: 2,
          quantity: 2
        },
        {
          id: 3,
          quantity: 2
        },
        {
          id: 4,
          quantity: 2
        },
        {
          id: 5,
          quantity: 2
        },
      ]
    }
  });
}

/*
export function updateUser(store) {
  update(ref(db, `users/${store.uid}`), {
    id: store.id,
    items: store.items,
    money: store.money,
    monsters: store.monsters
  });
}
*/

export function saveMonster(store) {
  // TODO: Move retrieving data to log in section

  /*
  const dbRef = ref(getDatabase());
  let user;
  get(child(dbRef, `users/${userId}`)).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val());
      user = snapshot.val()
    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  });

  set(ref(db, 'users/' + userId + '/monsters'), {
    monsters,
  });
  */

  update(ref(db, `users/${store.uid}`), {
    monsters: store.monsters
  });

}

export function getUserData(store) {
  const dbRef = ref(getDatabase());
  //console.group('userData')
  //console.log('before', store)

  return new Promise((resolve, reject) => {
    get(child(dbRef, `users/${store.uid}`)).then((snapshot) => {
      if (snapshot.exists()) {
        resolve(snapshot.val())
      } else {
        store.monsters = []
        store.items = {
          food: []
        }
      }
      //console.log('retrieved', store)
      //console.groupEnd()
      resolve(store)
    }).catch((error) => {
      console.error(error);
      reject()
    });
  })
}

export function getMonsterData(store) {
  const dbRef = ref(getDatabase());
  //console.group('monsters')
  //console.log('before', store.monsters)

  return new Promise((resolve, reject) => {
    get(child(dbRef, `users/${store.uid}/monsters`)).then((snapshot) => {
      if (snapshot.exists()) {
        resolve(snapshot.val())
      } else {
        store.monsters = []
      }
      //console.log('retrieved', store.monsters)
      //console.groupEnd()
      resolve(store)
    }).catch((error) => {
      console.error(error);
      reject()
    });
  })
}

export function getItemData(store) {
  const dbRef = ref(getDatabase());
  //console.group('items')
  //console.log('before', store.items)

  return new Promise((resolve, reject) => {
    get(child(dbRef, `users/${store.uid}/items`)).then((snapshot) => {
      if (snapshot.exists()) {
        resolve(snapshot.val())
      } else {
        store.items = []
      }
      //console.log('retrieved', store.items)
      //console.groupEnd()
      resolve(store)
    }).catch((error) => {
      console.error(error);
      reject()
    });
  })
}

export function getUserDataOnAuthStateChanged(uid, userStore) {
  const dbRef = ref(getDatabase());
  
  return new Promise((resolve, reject) => {
  get(child(dbRef, `users/${uid}`)).then((snapshot) => {
    if (snapshot.exists()) {
      let val = snapshot.val()
      userStore.uid = uid
      userStore.id = val.id
      userStore.money = val.money
      userStore.signedIn = true
      userStore.monsters = val.monsters
      userStore.items = val.items
      userStore.messages = val.messages || []
    } else {
      userStore.monsters = []
    }
    resolve()
  }).catch((error) => {
    console.error(error);
    reject()
  });
})

}

/*
export async function getMonsterData() {
  let monsterData

  const dbRef = ref(getDatabase());
  return get(child(dbRef, `monsters`)).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val());
      monsterData = snapshot.val()
      return monsterData
    } else {
      console.log("No data available");
    }
  }).catch((error) => {
    console.error(error);
  });
}
*/

export function getAssetsOnAuthStateChanged(assetsStore) {
  let numberOfMonsters = 10
  let monsters = []
  // let monsterAnimations = []
  for (let i = 1; i <= 9; i++) {
    monsters.push(require('@/assets/monsters/monster00' + i + '/monster00' + i + '.gif'))

    /*
    let allAnimationsCovered = false
    let j = 1
    while(!allAnimationsCovered) {
      try {
        monsterAnimations.push(require('@/assets/monsters/monster00' + i + '/monster00' + i + '-' + j + '.png'))
        j++
      } catch (e) {
        allAnimationsCovered = true
      }
    }

    assetsStore.monsterAnimations = monsterAnimations
    */

  }
  
  for (let i = 10; i <= numberOfMonsters; i++) {
    monsters.push(require('@/assets/monsters/monster0' + i + '/monster0' + i + '.gif'))

    /*
    let allAnimationsCovered = false
    let j = 1
    while(!allAnimationsCovered) {
      try {
        monsterAnimations.push(require('@/assets/monsters/monster0' + i + '/monster0' + i + '-' + j + '.png'))
        j++
      } catch (e) {
        allAnimationsCovered = true
      }
    }

    assetsStore.monsterAnimations = monsterAnimations
    */

  }
  assetsStore.monsters = monsters

  let platforms = []
  for (let i = 1; i <= 9; i++) {
    platforms.push(require('@/assets/platforms/' + 'platform00' + i + '.png'))
  }
  platforms.push(require('@/assets/platforms/' + 'platform010.png'))
  assetsStore.platforms = platforms

  let backgrounds = []
  for (let i = 1; i <= 9; i++) {
    backgrounds.push(require('@/assets/backgrounds/' + 'background00' + i + '.png'))
  }
  backgrounds.push(require('@/assets/backgrounds/' + 'background010.png'))
  assetsStore.backgrounds = backgrounds
}

export function persistMonsterState(userStore) {
  //console.log('persisting monster', userStore.monsters[0])
  update(ref(db, `users/${userStore.uid}`), {
    monsters: userStore.monsters
  });
}

export function persistItemsState(userStore) {
  //console.log('persisting items', userStore.items.food)
  update(ref(db, `users/${userStore.uid}`), {
    items: userStore.items
  });
}

export function persistMoneyState(userStore) {
  update(ref(db, `users/${userStore.uid}`), {
    money: userStore.money
  });
}

export function persistMessagesState(userStore) {
  update(ref(db, `users/${userStore.uid}`), {
    messages: userStore.messages
  });
}

export function storeConnectionRequestInCurrentUser() {
  // TODO
}

export function storeConnectionRequestInOtherUser() {
  // TODO
}

export function connectUsers() {
  // TODO
}

export function getFriendUser() {
  // TODO
}

export function retrieveMessages() {
  const dbRef = ref(getDatabase());

  return new Promise((resolve, reject) => {
    get(child(dbRef, 'messages')).then((snapshot) => {
      let messages = Object.values(snapshot.val()).map(el => {
        return {
          "meta": el.meta,
          "languages": el.languages
        }
      })

      messages.sort((a, b) => {
        const time1 = (new Date(a.meta.date)).getTime()
        const time2 = (new Date(b.meta.date)).getTime()
        return time2 - time1
      })

      resolve(messages)
    }).catch((error) => {
      console.error('messages could not be retrieved', error);
      reject()
    });
  })
}

export function deleteAccount(router) {

  const auth = getAuth();
  const user = auth.currentUser;

  remove(
    ref(db, `users/${user.uid}`)
  ).then(() => {
    console.log(`Profile of user ${user.uid} deleted successfully`);

    deleteUser(user).then(() => {
      console.log(`User ${user.uid} deleted successfully`);
      signOut(auth).then(() => {
        console.log('Sign out successful')
        router.push('/')
      }).catch((error) => {
        console.log('Sign out failed', error)
      });
    }).catch((error) => {
      console.log(`User ${user.uid} could not be deleted`, error);
    });

  })
  .catch((error) => {
    console.log(`Profile of user ${user.uid} could not be deleted`, error);
  });

}