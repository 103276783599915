import { defineStore } from 'pinia'

export const useAssetsStore = defineStore('assets', {
  state: () => {
    return {
      monsters: [],
      monsterAnimations: [],
      platforms: [],
      backgrounds: []
    }
  },

})