export default {
  "landingPage": {
    "p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you always been looking for a friend to take with you?"])},
    "p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Someone who is always there for you?"])},
    "p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guess who wants to be your friend!"])},
    "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monster Pets"])},
    "h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your digital pets"])},
    "p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feed your Monster Pet 🥪"])},
    "p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play with your Monster Pet ⚽️"])},
    "p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuddle your Monster Pet 🤗"])},
    "signUp1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up for our newsletter"])},
    "signUp2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" now to get notified about Monster Pet news!"])}
  },
  "newsletter": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up to our mailing list for updates about Monster Pets!"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name (Optional)"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name (Optional)"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
    "agree": {
      "part1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By subscribing, you agree with Revue's"])},
      "part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
      "part3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
      "part4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
      "part5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])}
    }
  },
  "game": {
    "modes": {
      "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy"])},
      "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
      "hard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hard"])}
    },
    "player": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You"])},
    "draw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw!"])},
    "playerWon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You won!"])},
    "monsterWon": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " won!"])},
    "confirmQuit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to quit?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "playAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play again"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
  },
  "shop": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "confirmationPart1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to buy ", _interpolate(_named("amount")), " ", _interpolate(_named("pluralizeBerry")), " for ", _interpolate(_named("price")), "x"])},
    "confirmationPart2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["?"])},
    "notEnoughMoney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You don't have enough money."])},
    "lessThanOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot buy less than one berry."])}
  },
  "messages": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])}
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "friends": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friends"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your connected friends:"])}
    },
    "openRequests": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requests"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your open requests:"])}
    },
    "yourId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your friend ID:"])},
    "idOfFriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID of your friend to connect to:"])},
    "nameOfFriend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your friends name:"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send friend request"])},
    "deleteProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Profile"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "dropdown": {
      "values": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-- Please select a category --"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feature Request"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bug"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
      ],
      "messages": {
        "initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a category."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your message!"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! There was a problem submitting your form."])}
      }
    }
  },
  "monsterView": {
    "happiness": {
      "high": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " is very happy"])},
      "medium": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " is ok"])},
      "low": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " is unhappy"])}
    },
    "replete": {
      "high": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " is very satiated"])},
      "medium": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " is a bit hungry"])},
      "low": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " is very hungry"])}
    },
    "love": {
      "high": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " loves you sooooooo much"])},
      "medium": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " likes you"])},
      "low": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("monster")), " does not like you that much"])}
    },
    "feed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Feed ", _interpolate(_named("monster"))])},
    "play": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Play with ", _interpolate(_named("monster"))])}
  },
  "signUp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up for Monster Pets"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password (at least 6 characters)"])},
    "passwordConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password (confirmed)"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])}
  },
  "signIn": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])}
  },
  "notFound": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops..."])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page could not be found. Maybe a monster pet ate it?!"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
  },
  "cookieConsent": {
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are using cookies to provide full functionality (login functionality)."])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])}
  },
  "monsterSelection": {
    "monster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your monster!"])},
    "platform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where does your monster live?"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the name of your monster?"])}
  },
  "footer": {
    "siteNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Notice"])},
    "dataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Protection"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
  },
  "siteNotice": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Notice"])},
    "information": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information pursuant to Sect. 5 German Telemedia Act (TMG)"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patricia Sauer"])},
      "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tannenweg 3"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D-16540 Hohen Neuendorf"])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
      "phone": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
        "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+49 (0) 171 170 94 26"])}
      },
      "email": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
      }
    },
    "disputeResolution": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumer Dispute Resolution/Universal Arbitration Board"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board."])}
    }
  },
  "moneyHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your monster money"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "closeMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close menu"])}
}