<template>
<div v-if="!userStore.signedIn">
  <SignIn redirect="monsters" />
</div>
<div v-else id="monster-area">
  <section>
    <div style="margin: auto;">

      <h1 style="margin-top: 1rem; text-transform: uppercase;">{{ $t('monsterSelection.monster') }}</h1>

      <div id="monster-wrapper">
        <div v-for="monster in assetsStore.monsters" :key="monster">
          <button @click="selectMonster(monster)">
            <img class="monster" :src="monster" alt="" />
          </button>  
        </div>
      </div>
    </div>
  </section>

  <section id="platform-area">

    <div style="margin: auto;">
      
      <h1 style="margin-top: 2rem; text-transform: uppercase;">{{ $t('monsterSelection.platform') }}</h1>

      <div id="platform-wrapper">
        <div v-for="platform in assetsStore.platforms" :key="platform">
          <button @click="selectPlatform(platform)">
            <img class="platform" :src="platform" alt="" />
          </button> 
        </div>
      </div>

    </div>
  </section>

  <section id="monster-name-area" :style=" { backgroundImage: 'url(' + selectedBackground + ')'  }">

    <div style="margin: auto; display: flex; flex-wrap: wrap; justify-content: center; flex-direction: column;">
      
      <h1 style="margin-top: 2rem; width: 80vw; align-self: center; text-transform: uppercase;">{{ $t('monsterSelection.name') }}</h1>

      <div style="display: flex; flex-flow: wrap; justify-content: center;">
        <img id="monster" :src="selectedMonster" alt="" />
      </div>

      <div id="name-area-wrapper">
        <div id="input-wrapper">
          <input id="name-area" v-model="name" @keyup.enter="directToMonsterView()" />
        </div>

        <div id="button-wrapper">
        <button id="go-button" @click="directToMonsterView()" class="button-large">
          <!-- TODO: Replace with image -->
          GO!
        </button>
        </div>
      </div>

    </div>
  </section>

  </div>
</template>

<script>
import { saveMonster } from '@/firebase/db'
import { useUserStore } from '@/stores/user'
import { getNature } from '@/natures'
import SignIn from '@/components/SignIn.vue'
import { useAssetsStore } from '@/stores/assets'
import { getUserDataOnAuthStateChanged } from "../firebase/db"

export default {
  name: "MonsterSelection",

  components: {
    SignIn
  },

  data() {
    return {
      userStore: useUserStore(),
      assetsStore: useAssetsStore(),
      activeMonsterId: 0,
      selectedPlatformId: 0,
      name: ''
    }
  },

  async created() {
    await getUserDataOnAuthStateChanged(window.localStorage.getItem("monsterPetUid"), this.userStore);
    if (this.userStore.monsters?.length > 0) {
      this.$router.push('/monsters')
    }
  },

  computed: {
    selectedMonster() {
      return this.assetsStore.monsters[this.activeMonsterId]
    },


    selectedBackground() {
      return this.assetsStore.backgrounds[this.selectedPlatformId]
    },

  },

  methods: {

    selectMonster(monster) {
      console.log('selected monster', monster)
      this.activeMonsterId = this.assetsStore.monsters.indexOf(monster)

      window.localStorage.setItem('monsterId', this.activeMonsterId)

      document.getElementById("platform-area").scrollIntoView({behavior: 'smooth'});
    },

    selectPlatform(platform) {
      console.log('selected platform', platform)
      let index = platform.indexOf('.')
      console.log(platform.substring(index - 3, index))

      this.selectedPlatformId = this.assetsStore.platforms.indexOf(platform)
      console.log(this.selectedPlatformId)

      window.localStorage.setItem('platformId', this.selectedPlatformId)

      document.getElementById("monster-name-area").scrollIntoView({behavior: 'smooth'});
    },

    directToMonsterView() {
      const now = Date.now()
      this.userStore.monsters = [
        {
          monsterId: this.activeMonsterId,
          platformId: this.selectedPlatformId,
          name: this.name,
          birthday: now,
          lastVisit: now,
          nature: getNature(),
          state: {
            happiness: {
              level: 50,
              lastModified: now
            },
            replete: {
              level: 50,
              lastModified: now
            },
            love: {
              level: 50,
              lastModified: now
            },
            bond: {
              level: 0,
              lastModified: now
            }
          },
        }
      ]

      saveMonster(this.userStore)

      window.localStorage.setItem('monsterId', this.activeMonsterId)
      window.localStorage.setItem('platformId', this.selectedPlatformId)

      // this.$router.push({ name: 'monster', params: { monsterId: now } })
      this.$router.push('/monsters')

      /* use later
      if (this.userStore.monsters.length === 1) {
        saveMonster(this.userStore)

        window.localStorage.setItem('monsterId', this.activeMonsterId)
        window.localStorage.setItem('platformId', this.selectedPlatformId)

        this.$router.push({ name: 'monster', params: { monsterId: now } })
      }
      */

    }

  }
}
</script>

<style lang="scss" scoped>
#monster-wrapper, #platform-wrapper {
  display: flex; 
  flex-flow: wrap; 
  justify-content: center;
  min-height: 100vh;
}

#monster-wrapper {
  align-content: center;
}

#monster-area {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
}

.monster, .platform {
  max-width: 250px;
}

#monster-name-area {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
}

#name-area-wrapper {
  display: flex; 
  flex-flow: wrap; 
  justify-content: center; 
}

#input-wrapper {
  background-color: rgba(255, 255, 255, 0.7);
  width: fit-content;
  border-radius: 25px;
}

#button-wrapper {
  width: fit-content;
  border-radius: 25px;
  margin-left: 2rem;
}

#name-area {
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid yellow;
  height: 2rem;
  padding: 0.5rem 0.5rem;
  border-radius: 25px;

  color: white;
  font-size: 1.5rem;
}

#name-area:focus {
  outline: none;
}

img:is(.platform, .monster):hover {
  transform: scale(1.25);
}

#go-button {
  background-color: rgba(255, 255, 255, 0.7);
  height: 100%;
}

h1 {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 25px;
  padding: 1rem 1rem;
}

@media (max-width: 375px) {
  .monster, #monster, .platform {
    width: 250px;
    height: 250px;
  }

  #button-wrapper {
    margin-left: 0;
    margin-top: 2rem;
  }

}
</style>