<template>
  <div>
    <div id="cookie-consent">
      <div id="cookie-consent-text">
        {{ $t('cookieConsent.content')}}
      </div>

      <div id="button-wrapper">
        <button id="decline" @click="setCookieAccepted(false)">{{ $t('cookieConsent.decline')}}</button>
        <button id="accept" @click="setCookieAccepted(true)">{{ $t('cookieConsent.accept')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {

    setCookieAccepted(val) {
      window.localStorage.setItem('cookiesAccepted', val.toString())
      this.$emit('cookiesAccepted', val)
    }

  },
}
</script>

<style scoped>
#cookie-consent {
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;

  background: radial-gradient(var(--yellow-light), var(--yellow-dark));
  border: 1px solid black;

  border-radius: 25px;

  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  padding: 2rem;

  -webkit-box-shadow: 0px 0px 8px var(--background-color);
  -moz-box-shadow: 0px 0px 8px var(--background-color);
  box-shadow: 0px 0px 8px var(--background-color);
  width: 400px;
}

#button-wrapper {
  display: flex;
  justify-content: center;
}

#decline,
#accept {
  font-size: 1rem;
  border-radius: 25px;
  height: 50px;
  margin: 0 1rem;
  text-transform: uppercase;
  letter-spacing: 1.76px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 700;
}

#decline {
  color: var(--background-color);
  border: 0.15rem solid var(--btn-color);
  background-color: var(--btn-color);
}

#decline:hover {
  border: 0.15rem solid var(--btn-color-active);
  background-color: var(--btn-color-hover);
}

#decline:active {
  color: var(--btn-color);
  border: 0.15rem solid var(--btn-color-hover);
  background-color: var(--btn-color-active);
}

#accept {
  color: var(--btn-color);
  border: 0.15rem solid var(--background-color);
  background-color: var(--background-color);
}

#accept:hover {
  border: 0.15rem solid var(--background-color-active);
  background-color: var(--background-color-hover);
}

#accept:active {
  color: var(--background-color);
  border: 0.15rem solid var(--background-color-hover);
  background-color: var(--background-color-active);
}

#cookie-consent-text {
  margin-bottom: 1rem;
}

@media (max-width: 500px) {
  #cookie-consent {
    padding: 1rem;
    width: 70vw;
  }

  #button-wrapper {
    flex-flow: column-reverse;
    align-content: center;
  }

  #decline {
    margin-top: 1rem;
  }

}
</style>