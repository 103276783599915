<template>
  <div>
    <div id="closing-area" style="align-self: flex-end">
      <button 
        id="close" 
        class="menu-btn image-button-smallest" 
        :title="$t('closeMenu')"
        @click="closeGame()"
      />
    </div>

    <!-- <div id="score-container">
      <div id="user-score" :class="{ bold: isUserTurn }">{{ $t("game.player")}}: {{ userScore }}</div>
      <div id="ai-score" :class="{ bold: !isUserTurn }">{{ monsterName }}: {{ aiScore }}</div>
    </div> -->

    <div id="quit-confirmation" :class="{ visible: confirmQuit }">
      {{ $t('game.confirmQuit')}}

      <div id="quit-confirmation-button-container">
        <button id="quit-confirm-button" class="button-large-negative" @click="closeGame()">{{ $t('game.yes') }}</button>
        <button id="quit-deny-button" class="button-large-positive" @click="this.confirmQuit = false">{{ $t('game.no') }}</button>
      </div>
    </div>

    <div id="game-over" :class="{ visible: isGameOver }">
      <div id="game-over-button-container">
        <div>
        <button
          id="back-button-quit"
          class="button-large-positive"
          @click="closeGame()">
          {{ $t('game.back') }}
        </button>
        </div>
        <div>
          <button id="play-again-button" class="button-large-positive" @click="resetGameState()">{{ $t('game.playAgain') }}</button>
        </div>
      </div>
    </div>

    <div style="display: flex; flex-direction: column;">
      <div id="completed-image-container" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
        
        <div class="control-button-wrapper" v-if="selectMode">
          <button id="prev-monster-btn" class="menu-btn image-button-small control-wrapper-element" @click="selectMonster(currentMonsterIndex-1)"></button>
          <img class="control-wrapper-element" :src="displaySelectedMonster()" alt="">
          <button id="next-monster-btn" class="menu-btn image-button-small control-wrapper-element" @click="selectMonster(currentMonsterIndex+1)"></button>
        </div>

        <div class="control-button-wrapper" v-if="selectMode">
          <button id="prev-monster-btn" class="menu-btn image-button-small control-wrapper-element" @click="selectBackground(currentBackgroundIndex-1)"></button>
          <img class="control-wrapper-element" :src="displaySelectedBackground()" alt="">
          <button id="next-monster-btn" class="menu-btn image-button-small control-wrapper-element" @click="selectBackground(currentBackgroundIndex+1)"></button>
        </div>

        <div v-if="!selectMode">
          <img id="selected-monster" :src="displaySelectedBackground()" alt="">
        </div>

      </div>

      <div id="puzzle-container" v-if="!selectMode" :style="{ 'grid-template-columns': gridTemplateColumns }">
        <button 
          class="puzzle-item" 
          v-for="(slice, idx) in slices" 
          :key="idx" 
          @click="moveSlice(slice)" 
          :style="{
            border: slice.img === null ? '3px solid white' : '3px solid black',
            backgroundColor: slice.img === null ? 'white': 'unset',
            borderRadius: '5px',
            backgroundImage: 'url(' + slice.img + ')',
          }">
          <!-- <img v-if="slice.img !== null" class="slice" :src="slice.img" alt="" width="50" height="50"> -->
        </button>
      </div>
    </div>

    <div id="mode-selection" :class="{ visiblebottom: selectMode }">
      <button id="easy" class="button-large-positive" @click="switchMode('easy')">3 x 3</button>
      <button id="medium" class="button-large-positive" @click="switchMode('medium')">4 x 4</button>
      <button id="hard" class="button-large-positive" @click="switchMode('hard')">5 x 5</button>
    </div>

    <!-- <div id="continue-button-wrapper">
      <button id="continue-button" class="button-large-positive" :class="{ visible: displayContinueButton }" @click="flipCardsBack()">{{ $t('game.continue') }}</button>
    </div> -->
  </div>
  
</template>

<script>
import { EASY, MEDIUM, HARD } from '@/gameModes'
import { moveSlice, checkGameFinished } from '../puzzle.js'
import { useUserStore } from '@/stores/user'
import { persistMonsterState } from '@/firebase/db'

export default {
  data() {
    return {
      userStore: useUserStore(),
      mode: EASY,
      isGameOver: false,
      confirmQuit: false,
      selectMode: true,
      clickableFields: false,
      numberOfSlices: 9,
      slices: [],
      monsters: ['monster001', 'monster002', 'monster003', 'monster004', 'monster005', 'monster006', 'monster007', 'monster008', 'monster009', 'monster010'],
      backgrounds: ['background000', 'background001', 'background002', 'background003', 'background004', 'background005', 'background006', 'background007', 'background008', 'background009', 'background010'],
      currentMonsterIndex: 0,
      currentBackgroundIndex: 0,
      selectedMonster: 'monster001',
      selectedBackground: 'background000',
    }
  },

  mounted() {
    this.resetGameState()
    this.createSelectedMonsterArray()
  },

  methods: {
    closeGame() {
      if (!this.isGameOver && !this.confirmQuit) {
        this.confirmQuit = true
        return
      }

      this.resetGameState()
      this.$emit('closeGame')
    },

    switchMode(mode) {
      switch (mode) {
        case EASY:   this.mode = EASY;
                     break;
        case MEDIUM: this.mode = MEDIUM;
                     break;
        case HARD:   this.mode = HARD;
                     break;
        default: console.log('unknown mode', mode)
      }
      this.selectMode = false
      this.clickableFields = true
      this.shuffle()
    },

    selectMonster(index) {
      if (index < 0) {
        index = this.monsters.length-1
      }
      if (index >= this.monsters.length) {
        index = 0
      }

      this.currentMonsterIndex = index
      this.selectedMonster = this.monsters[this.currentMonsterIndex]
      this.createSelectedMonsterArray()
      this.shuffle()
    },

    selectBackground(index) {
      if (index < 0) {
        index = this.backgrounds.length-1
      }
      if (index >= this.backgrounds.length) {
        index = 0
      }

      this.currentBackgroundIndex = index
      this.selectedBackground = this.backgrounds[this.currentBackgroundIndex]
      this.createSelectedMonsterArray()
      this.shuffle()
    },

    displaySelectedMonster() {
      return require(`../assets/monsters/${this.selectedMonster}/${this.selectedMonster}.png`)
    },

    displaySelectedBackground() {
      return require(`../assets/slider-puzzle/${this.selectedMonster}/${this.selectedMonster}-${this.selectedBackground}/${this.selectedMonster}-${this.selectedBackground}.png`)
    },

    createSelectedMonsterArray() {
      this.slices = []
      this.numberOfSlices = this.mode === EASY ? 9 : this.mode === MEDIUM ? 16 : 25
      const n = Math.sqrt(this.numberOfSlices);
      for (let i = 0; i < n; i++) {
        for (let j = 0; j < n; j++) {
          const index = i * n + j;
          this.slices.push({
            pos: index,
            x: j,
            y: i,
            img: require(`@/assets/slider-puzzle/${this.selectedMonster}/${this.selectedMonster}-${this.selectedBackground}/${this.numberOfSlices}/slice_${index}.png`),
            empty: false
          });
        }
      }

      this.slices.pop()
      this.slices.push({pos: this.numberOfSlices-1, x: 2, y: 2, img: null, empty: true})
    },

    shuffle() {
      this.createSelectedMonsterArray()

      // let temp = this.slices[this.numberOfSlices-2];
      // this.slices[this.numberOfSlices-2] = this.slices[this.numberOfSlices-1];
      // this.slices[this.numberOfSlices-1] = temp;

      function shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
      }

      shuffle(this.slices)
    },

    moveSlice(slice) {
      this.slices = moveSlice(slice, this.slices)
      this.isGameOver = checkGameFinished(this.slices)

      if (this.isGameOver) {

        this.$confetti.start(
        //   {
        //   canvasElement: document.getElementById('confetti'),
        // }
        );
        // setTimeout(() => {
        //   this.$confetti.stop();
        //   // document.getElementById('puzzle-container').style.filter = 'blur(4px)'
        // }, 2000);

      for (let stateToIncrease of ['love', 'happiness']) {
        this.currentMonster.state[stateToIncrease].level += 10
        if (this.currentMonster.state[stateToIncrease].level > 100) {
          this.currentMonster.state[stateToIncrease].level = 100
        }
        this.currentMonster.state[stateToIncrease].lastModified = Date.now();
      }

      persistMonsterState(this.userStore)
      }
    },

    resetGameState() {
      this.$confetti.stop()
      this.clickableFields = false

      this.isGameOver = false
      this.confirmQuit = false
      this.selectMode = true

      this.slices = []
      this.selectedMonster = 'monster001'
      this.selectedBackground = 'background000'

      // document.getElementById('puzzle-container').style.filter = 'blur(0)'
    }
  },

  computed: {
    gridTemplateColumns() {
      return `repeat(${Math.sqrt(this.numberOfSlices)}, minmax(25px, 50px))`;
    },

    currentMonster() {
      const route = this.$router.currentRoute
      return this.userStore.monsters.find(m => String(m.birthday) === route.value.params.monsterId)
    },
  }

}
</script>

<style lang="scss">
#puzzle-container {
  display: grid;
  row-gap: 1px;
  column-gap: 8px;
  width: fit-content;
  align-self: center;
  align-items: start;
}

.control-button-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create 3 equal-sized columns */
  grid-gap: 10px; /* Add some gap between the elements */
  max-width: 100%; /* Ensure the container doesn't exceed the screen width */
  justify-items: center;
  align-items: center;
  margin-bottom: 1rem;
}

.control-wrapper-element {
  max-width: 100%;
}

#prev-monster-btn {
  background-image: url('@/assets/misc/arrow-left.png');
  background-size: cover;
  width: 50px;
  height: 50px;
}

#next-monster-btn {
  background-image: url('@/assets/misc/arrow-right.png');
  background-size: cover;
  width: 50px;
  height: 50px;
}

#selected-monster {
  max-width: 50%;
  object-fit: contain;
  margin-top: 1rem;
  margin-bottom: 1rem;
  // border: 3px solid black;
  border-radius: 5px;
}

.puzzle-item {
  border: 3px solid black;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  padding: 50%;
  // width: 50px;
  // height: 50px;
}

.slice {
  width: 100%;
  height: auto;
}

#game-over, #mode-selection, #continue-button {
  display: none;
}

#mode-selection.visiblebottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;

  position: relative;
  align-self: center;
  transform: initial;
  top: 0%;
  right: 0%;

  background: radial-gradient(var(--yellow-light), var(--yellow-dark));
  border: 1px solid black;
  border-radius: 25px;

  width: 200px;

  .mode-button {
    margin: 1rem 0;
    width: 150px;

    color: var(--font-color);

    border: 2px solid var(--font-color);
    background-color: white;
  }
}

#easy, #medium, #hard {
  margin-bottom: 1rem;
}

#easy {
  margin-top: 1rem;
}

#quit-confirmation {
  display: none;
}

#quit-confirmation.visible {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;

  #quit-confirmation-button-container {
    display: flex;
    justify-content: space-around;
    padding: 1rem 1rem;
  }
}

@media (min-width: 375px) {
  // #puzzle-container {
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    // grid-template-rows: 75px 75px 75px 75px 75px;
    // width: 250px;

    // padding: 1rem 1rem 0 1rem;
  // }
}

@media (min-width: 500px) {
  #continue-button {
    margin-top: 1rem;
  }
}
</style>