export const foods = {
  1: {
    name: 'item 1',
    description: {
      en: 'A berry which your monster will like.',
      de: 'Eine Beere, die dein Monster mögen wird.'
    },
    price: 10,
    replete: 10,
    happiness: 0,
    love: 0
  },
  2: {
    name: 'item 2',
    description: {
      en: 'A berry which your monster will like and which will increase the love of your monster for you a bit.',
      de: 'Eine Beere, die dein Monster mögen wird. Seine Liebe für dich wird sich ein wenig erhöhen.'
    },
    price: 20,
    replete: 10,
    happiness: 0,
    love: 10
  },
  3: {
    name: 'item 3',
    description: {
      en: 'A berry which your monster will like and which will increase it\'s happiness a bit.',
      de: 'Eine Beere, die dein Monster mögen wird. Seine Zufriedenheit wird sich ein wenig erhöhen.'
    },
    price: 20,
    replete: 10,
    happiness: 10,
    love: 0
  },
  4: {
    name: 'item 4',
    description: {
      en: 'A berry which your monster will like and which will increase all of it\'s stats a bit.',
      de: 'Eine Beere, die dein Monster mögen wird. All seine Statuswerte werden sich ein wenig erhöhen.'
    },
    price: 30,
    replete: 10,
    happiness: 10,
    love: 10
  },
  5: {
    name: 'item 5',
    description: {
      en: 'A berry which your monster will like a lot and which will increase all of it\'s stats.',
      de: 'Eine Beere, die dein Monster sehr mögen wird. All seine Statuswerte werden sich erhöhen.'
    },
    price: 50,
    replete: 20,
    happiness: 20,
    love: 20
  },
}